import React from "react";
import "./OfferingCard.css";
import rightarrow from "../../assets/utility_images/right-arrow-icon.svg";
import { Link } from "react-router-dom";

const OfferingCard = ({ number, icon, title, description, buttonName }) => {
  // Generate a slug from the title
  const generateSlug = (text) => {
    return text
      .toLowerCase()
      .replace(/\s+/g, "")
      .replace(/[^\w-]+/g, "");
  };

  const cardSlug = generateSlug(title);

  return (
    <div className="offering-card">
      <div className="number-box d-flex align-items-center justify-content-around">
        {number}
      </div>
      <div className="description-offering d-flex flex-column">
        <div className="offering-icon">
          <img src={icon} alt={title} />
        </div>
        <div className="horizontal-lines d-flex ">
          <div className="line-1"></div>
          <div className="line-2"></div>
        </div>

        <div className="offering-desc d-flex flex-column ">
          <div className="offering-title">{title}</div>
          <div className="offering-text">{description}</div>
          <Link
            className="linkToPage"
            to={`/${cardSlug}`} // Use the slug in the URL
            target="_blank"
          >
            <button className="btn-offering-card d-flex justify-content-evenly align-items-center">
              {buttonName}
              <img src={rightarrow} alt="" />
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OfferingCard;
