import React, { useState } from "react";
import "./Careers.css";
import { Row, Col, Container } from "react-bootstrap";
import { useLanguage } from "../../Context/LanguageContext";
import { useQuery } from "@tanstack/react-query";
import JobCard from "../../components/JobCard/JobCard";
import corner1 from "../../assets/utility_images/corner1.svg";
import corner2 from "../../assets/utility_images/corner2.svg";
import quotes from "../../assets/utility_images/quotation.svg";
import { Link as ScrollLink } from "react-scroll";
import JobApplicationModal from "../../components/JobApplicationModal/JobApplicationModal";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import fallbackDataCareersEN from "../../FallbackAPI/CareersLocal/FallbackApiCareersEN.json";
import fallbackDataCareersKR from "../../FallbackAPI/CareersLocal/FallbackApiCareersKR.json";
import arrowRight from "../../assets/utility_images/arrow-right.svg";

const Careers = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { language } = useLanguage();

  // Mapping localized titles to English for API compatibility
  const titleMapping = {
    "자바 개발자": "Java Developer",
    "안드로이드 개발자": "Android Developer",
    // Add other mappings here
  };

  const getEnglishTitle = (localizedTitle) =>
    titleMapping[localizedTitle] || localizedTitle;

  const {
    data: CareersApiData,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["CareersData", language],
    queryFn: async () => {
      try {
        const response = await fetch(`${baseUrl}/page/Careers/${language}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch careers data");
        }
        return response.json();
      } catch (error) {
        console.warn("API request failed, using fallback data.");
        return language === "en"
          ? fallbackDataCareersEN
          : fallbackDataCareersKR;
      }
    },
    staleTime: 10 * 60 * 1000,
    cacheTime: 60 * 60 * 1000,
    retry: 2,
    refetchOnWindowFocus: false,
  });

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error loading data.</div>;
  }

  const renderSection = (section) => {
    switch (section.sectionName) {
      case "JobSection":
        return (
          <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <section className="job-section" id="jobs">
              <div className="job">
                <div className="corner-1">
                  <img src={corner1} alt="" />
                </div>
                <div className="heading-container d-flex flex-column align-items-start">
                  <div className="section-heading d-flex align-items-center">
                    <div className="line"></div>
                    <div className="section-title">{section.heading}</div>
                  </div>
                  <div className="pillar-header">{section.introText}</div>
                </div>
                <div className="job-container">
                  <Row xs={2} sm={2} md={3} lg={3} xl={4}>
                    {section.sectionItems
                      .filter((item) => item.isEnabled)
                      .sort((a, b) => a.order - b.order)
                      .map((item) => (
                        <Col className="pb-4" key={item.id}>
                          <Link
                            className="linkToPage"
                            to={`/jobs/${getEnglishTitle(item.title)
                              .toLowerCase()
                              .replace(/\s+/g, "")}`}
                              target="_blank"
                          >
                            <JobCard heading={item.title} />
                          </Link>
                        </Col>
                      ))}
                  </Row>
                </div>
                <div className="corner-2">
                  <img src={corner2} alt="" />
                </div>
              </div>
            </section>
          </Container>
        );

      case "QuoteSection":
        return (
          <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <section className="quote-section">
              <div className="quote d-flex justify-content-between">
                <div className="quote-img">
                  <img src={quotes} alt="" />
                </div>
                <div className="quote-container d-flex flex-column align-items-start">
                  <div className="statement">{section.heading}</div>
                  <div className="author-name">{section.introText}</div>
                </div>
              </div>
            </section>
          </Container>
        );

      case "FormSection":
        return (
          <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <section className="form-section">
              <div className="form">
                <div className="form-section-content d-flex flex-column align-items-center">
                  <div className="heading">{section.heading}</div>
                  <div className="form-submission ">
                    {section.introText}
                    <button className="job-form-button" onClick={handleShow}>
                      <span>{section.buttonText}</span>
                    </button>
                    &nbsp;
                    {section.description}
                  </div>
                </div>
              </div>
            </section>
            <JobApplicationModal show={show} handleClose={handleClose} />;
          </Container>
        );

      default:
        return null;
    }
  };

  return (
    <div>
      {CareersApiData.viewPort.isEnabled && (
        <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
          <section className="Hero">
            <div className="hero-section">
              <div className="hero-banner d-flex">
                <div className="background-hero d-flex justify-content-start align-items-center">
                  <img
                    src={CareersApiData.viewPort.illustrationUrl}
                    alt="About Us"
                  />
                  <div className="hero-description d-flex flex-column">
                    <div className="careers-heading">
                      {CareersApiData.viewPort.heading
                        .split(",")
                        .map((part, index) => (
                          <React.Fragment key={index}>
                            <span>{part.trim()}</span>
                            {index !==
                              CareersApiData.viewPort.heading.split(",")
                                .length -
                                1 && <br />}
                          </React.Fragment>
                        ))}
                    </div>
                    <div className="careers-paragraph">
                      <p>{CareersApiData.viewPort.description}</p>
                    </div>
                    <ScrollLink
                      to="jobs"
                      smooth={true}
                      duration={200}
                      offset={-50}
                      className="linkToPage"
                    >
                      <button className="btn-hero d-flex justify-content-evenly align-items-center">
                        {CareersApiData.viewPort.buttonText}
                        <div className="hero-btn">
                          <img src={arrowRight} alt="" />
                        </div>
                      </button>
                    </ScrollLink>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Container>
      )}

      {CareersApiData.sections
        .filter((section) => section.isEnabled)
        .sort((a, b) => a.sectionOrder - b.sectionOrder)
        .map((section) => renderSection(section))}
    </div>
  );
};

export default Careers;
