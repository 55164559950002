// src/components/ContactModal.js
import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import emailjs from "emailjs-com";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the CSS for React Toastify

const ContactModal = ({ show, handleClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handlePhoneChange = (phone) => {
    setFormData((prev) => ({ ...prev, phone }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Send email using EmailJS
    emailjs
      .send("srajan2303", "srajan23", formData, "lWzokgQj4V54uZqiu")
      .then(
        (response) => {
          // console.log(
          //   "Email sent successfully!",
          //   response.status,
          //   response.text
          // );
          toast.success("Your query has been recieved!"); // Show success toast
        },
        (err) => {
          // console.error("Failed to send email. Error: ", err);
          toast.error("Failed to send your query. Please try again."); // Show error toast
        }
      )
      .finally(() => {
        // Reset form data
        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
        handleClose(); // Close the contact modal
      });
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Contact Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formName">
              <Form.Label>
                Name <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter your name"
                required
              />
            </Form.Group>

            <Form.Group controlId="formEmail" className="mt-3">
              <Form.Label>
                Email <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email"
                required
              />
            </Form.Group>

            <Form.Group controlId="formPhone" className="mt-3">
              <Form.Label>
                Phone Number <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <PhoneInput
                country={"in"}
                value={formData.phone}
                onChange={handlePhoneChange}
                inputStyle={{ width: "100%" }}
                required
                placeholder="Enter your phone number"
              />
            </Form.Group>

            <Form.Group controlId="formMessage" className="mt-3">
              <Form.Label>
                Message <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows={3}
                placeholder="Enter your query"
                required
              />
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-3">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <ToastContainer
        position="top-center" // Position of the toast notifications
        autoClose={5000} // Duration in milliseconds before the toast disappears
        hideProgressBar={false} // Show progress bar
        newestOnTop={false} // Newest toast on top
        closeOnClick // Close on click
        rtl={false} // Right-to-left
        pauseOnFocusLoss // Pause when window loses focus
        draggable // Allow dragging
        pauseOnHover={false}
      />
    </>
  );
};

export default ContactModal;
