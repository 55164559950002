import React from "react";
import "./ProcessCard.css";
// import processimage from "../../assets/utility_images/ourprocessimage.svg";

const ProcessCard = ({
  icon,
  heading,
  description,
  cardNumber,
  processImageColor,
}) => {
  return (
    <div className="process-card">
      <div className="process-card-content d-flex flex-column align-items-start">
        <div>
          <img src={icon} alt={heading} />
        </div>
        <div className="process-card-heading">{heading}</div>
        <div className="process-card-desc">{description}</div>
      </div>
      <div className="square" style={{ backgroundColor: processImageColor }}>
        <div className="card-number">{cardNumber}</div>
      </div>
      <div className="process-image">
        {" "}
        <svg
          width="252"
          height="431"
          viewBox="0 0 252 431"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ fill: processImageColor }} // Apply the color through inline styles
        >
          <path d="M8.85548 9.59111L251.099 5.00012L0.67334 0.810547V430.165L251.099 425.975L8.85548 421.384V9.59111Z" />
        </svg>
      </div>
    </div>
  );
};

export default ProcessCard;
