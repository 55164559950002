import React from 'react'
import "./PrivacyPage.css"
import {Container } from "react-bootstrap";

const PrivacyPage = () => {
  return (
    <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
    <section className="privacy-policy-section">
      <div className="privacy-policy">
        <div className="policy-description">
         
          <div className="privacy-title"> about us</div>
          <div className="privacy-description">
            Venus Browser is a traditional browser with content aggregation
            platform catered towards most popular web App links and regional
            contents in India. User can read news in regional languages;
            compare commerce offers from different players, save articles
            for offline reading, Quick access of essential services, access
            nearby and local news starting from villages to metro cities in
            India. Venus prioritizes and serves most popular contents in
            various categories. Version 1.0.9 BS Inventions Private Limited
            (“BlueSky Inventions”)

            <div className="privacy-title"> Privacy Policy </div>

            THIS PRIVACY POLICY (“Policy”) has been drafted in accordance
            with the Information Technology Act, 2000 and the Information
            Technology (Reasonable security practices and procedures and
            sensitive personal data or information) Rules, 2011 and
            Information Technology (Intermediaries guidelines) Rules, 2011.
            THIS POLICY DESCRIBES OUR POLICIES AND PROCEDURES FOR
            COLLECTION, USE, STORAGE, PROCESSING, DISCLOSURE AND PROTECTION
            OF ANY INFORMATION, INCLUDING, BUT NOT LIMITED TO, BUSINESS OR
            PERSONAL INFORMATION PROVIDED BY YOU AS A USER (“YOU/R”) WHILE
            USING THE VENUS BROWSER. USER SHALL MEAN ANY PERSON/ PERSONS,
            WHO VISITS, USES, DEALS WITH AND/ OR TRANSACTS THROUGH THE VENUS
            BROWSER OR AVAILS OUR SERVICES. BY VISITNG/ ACCESSING VENUS
            BROWSER, THROUGH THE WEBSITE OR THE APP AND VOLUNTARILY
            PROVIDING US WITH INFORMATION (PERSONAL AND/ OR NON- PERSONAL)
            AS SPECIFIED HEREIN, YOU ARE CONSENTING TO OUR USE OF IT IN
            ACCORDANCE WITH THIS POLICY. THIS POLICY DOES NOT APPLY TO
            THIRD-PARTY WEBSITES / APPLICATIONS THAT ARE CONNECTED VIA LINKS
            TO OUR VENUS BROWSER. IF YOU DO NOT AGREE WITH THE TERMS AND
            CONDITIONS OF THIS PRIVACY POLICY, PLEASE DO NOT PROCEED FURTHER
            TO USE / ACCESS THE VENUS BROSWER.
            <br />
            <br />
            YOUR USE OF VENUS BROWSER WILL BE GOVERNED BY THIS POLICY AS
            APPLICABLE TOGETHER WITH ALL POLICIES, NOTICES, GUIDELINES,
            DISCLAIMERS THAT ARE PUBLISHED AND SHARED WITH YOU FROM TIME TO
            TIME WHICH ARE INCORPORATED HEREIN BY WAY OF REFERENCE INCLUDING
            BUT NOT LIMITED TO OUR TERMS & CONDITIONS AND SUCH OTHER TERMS
            AS MAY BE APPLICABLE TO YOU IN YOUR CAPACITY AS A USER OF VENUS
            BROWSER. THIS PRIVACY POLICY SHALL BE ENFORCEABLE AGAINST YOU IN
            THE SAME MANNER AS ANY OTHER WRITTEN AGREEMENT.
            <br />
            <br />
            THIS POLICY IS A PART OF THE VENUS BROWSER TERMS & CONDITIONS.
            <br />
            <br />
            1. Information BlueSky Inventions may collect from the User:
            <br />
            <br />
            BlueSky Inventions may collect Your personal information in
            various ways, including but not limited to, user information
            submitted during download and installation of the App or in
            connection with registration for other activities or features
            offered through the App. You may be asked for details like name,
            email address, phone number and address. BlueSky Inventions will
            collect Your personally identifiable information only if You
            voluntarily submit such information to BlueSky Inventions. You
            can always refuse to supply personally identifiable information,
            except that it may prevent You from using the App, or certain
            features of the App.
            <br />
            <br />
            BlueSky Inventions may collect Your non-personal information
            whenever You access or interact with the App. Non-personal
            identification information may include the mobile name, version,
            the type of device used, users' operating system and version,
            the unique device ID of the mobile device, third party apps or
            website or service that referred You to this App, language
            preference, location information, IP address, technical
            information and other similar information about the User.
            <br />
            <br />
            2. BlueSky Inventions may collect and use Your personal
            information for the following purposes: Your email address and
            password or Application ID or IMEI or GCM ID are used to
            identify You while logging into the App. BlueSky Inventions may
            use this information to control Your access to the App as well
            as use of the App, to communicate with the User, customize Your
            experience of using the App and and/or the content of any email
            newsletter or other material that BlueSky Inventions may send to
            You from time to time and provide information that may be useful
            or interesting based on the content and personally identifiable
            information relating to You.
            <br />
            <br />
            Your email address or Application ID or IMEI or GCM ID will be
            used to send user information, administrative information,
            changes in account settings and any changes to the App or
            updating User on new policies of BlueSky Inventions. Apart from
            this if You choose to opt-in to our mailing list, You will
            receive periodic emails that may relate to company news, related
            product or service information, etc. Email address may also be
            used for responding to any of the inquiries, questions, and/or
            any other requests made by You. If at any time You want to
            unsubscribe from receiving future emails, BlueSky Inventions has
            included detailed unsubscribe instructions at the bottom of each
            email or You may contact BlueSky Inventions via the App.
            <br />
            <br />
            To analyse the use of the App, and the people visiting and using
            the App, for the purpose of improving the features of the App.
            Information provided by You helps BlueSky Inventions in
            responding to Your customer service requests and support needs
            more efficiently. BlueSky Inventions may use information in the
            aggregate to understand how users are, as a group are using the
            App.
            <br />
            <br />
            BlueSky Inventions may use the personal information provided by
            the User to prevent or take action against activities that are,
            or may be, in breach of the "BlueSky User Agreement", "Terms and
            Conditions" and any applicable laws.
            <br />
            <br />
            3. How BlueSky Inventions protects User's Information
            <br />
            <br />
            BlueSky Inventions adopts appropriate data collection, storage
            and processing practices and security measures to protect
            against unauthorized access, alteration, disclosure or
            destruction of Your personal information, username, password,
            and data stored on the App. Sensitive and private data exchange
            between the Application and its users happens over a SSL (Secure
            Socket Layer) secured communication channel. However, BlueSky
            Inventions cannot guarantee the security of any information
            obtained through unauthorized entry or use, hardware or software
            failure, and other factors that may compromise the security of
            user information at any time. It is important for the User to
            prevent unauthorized access to his/her account and personal
            information by limiting access to his/her device.
            <br />
            <br />
            4. Sharing User's Personal Information
            <br />
            <br />
            BlueSky Inventions does not sell, trade, or rent Your personally
            identifiable information collected by BlueSky Inventions to
            others except as described in this Policy. BlueSky Inventions
            may share generic aggregated demographic information not linked
            to any personally identifiable information regarding visitors
            and users with BlueSky Inventions business partners, trusted
            affiliates and advertisers for the purposes outlined above.
            <br />
            <br />
            At times BlueSky Inventions may be required by law or litigation
            to disclose personal information about the users. BlueSky
            Inventions may also disclose information about the user if
            BlueSky Inventions determines that for national security, law
            enforcement, or other issues of public importance that
            disclosure of information is necessary.
            <br />
            <br />
            BlueSky Inventions reserves the right to transfer Personal
            Information of users to another party as a result of merger,
            acquisition, or sale of all or a portion of BlueSky Invention’s
            assets to another party.
            <br />
            <br />
            5. What Choices User has Regarding the Use of his/her
            Information
            <br />
            <br />
            In case, if the user is receiving marketing or promotional
            emails from BlueSky Inventions, You have the option to "opt out"
            of such marketing emails by following the instructions in those
            mails or by emailing BlueSky Inventions at
            reachus@blueskyinvent.com . If the user opts out, he/she may
            still receive non-promotional emails from BlueSky Inventions,
            such as emails about his/her account or any ongoing business
            relations entered into by BlueSky Inventions.
            <br />
            <br />
            6. Changes to this Privacy Policy
            <br />
            <br />
            BlueSky Inventions may update and revise this Privacy Policy
            from time to time. The revised Privacy Policy will be posted as
            notification here on this link http://blueskyinvent.com/privacy
            You are encouraged to periodically check this page to stay
            informed about changes to this Privacy Policy. You hereby
            acknowledge and agree that it is Your responsibility to review
            this Privacy Policy periodically and become aware of the
            modifications. If You disagree to any of the changes to the
            Privacy Policy, You shall refrain from using or accessing the
            App. Your continued use of the App following the posting of the
            revised Policy shall indicate your acceptance and
            acknowledgement of the changes and you will be bound by it.
            <br />
            <br />
            7. Your Acceptance of these terms of Privacy Policy
            <br />
            <br />
            By using this App, You are signifying your acceptance of this
            Privacy Policy. If you do not agree to this Privacy Policy, You
            shall not access or use this App. Your continued Access or Use
            of the App following the posting of changes to this Privacy
            Policy will be deemed to be the acceptance of these changes by
            the User.
            <br />
            <br />
            8. Contacting Us If You have any questions about this Privacy
            Policy, practices of this App or Your dealings with the App or
            any of the features or any Legal Queries about the App , please
            contact BlueSky Inventions at: reachus@blueskyinvent.com.  
            <br />
            <br />
            <br />

            <div className="privacy-title"> TERMS AND CONDITIONS</div>

            You must carefully read and understand these terms and
            conditions before downloading, installing and using the Venus
            Browser Mobile Application =, its related website and mobile
            site (“Venus” or “Venus Browser” or “Browser”) which is owned
            and distributed by BS Inventions Private Limited, having its
            registered office at BlueSky Inventions, #634, 4th Main, OMBR
            Layout, Bangalore, Karnataka 560043, India (hereinafter referred
            to as “BlueSky Inventions”, “we” and “us”). This terms and
            conditions (hereinafter referred to as "terms") as well as the
            privacy policy, and all other applicable laws and regulations
            governs your access and use of the Venus Browser, irrespective
            of whether you are a registered user or a visitor (which means
            that you simply browse the Venus Browser without limitation,
            through a mobile or other wireless device, or otherwise use the
            Venus Browser without being registered) the terminologies "You",
            "Your", "User" and "users" shall be read in context, and shall
            refer to you. by using or accessing or downloading the Venus
            Browser to view contents by you, you agree to be bound by these
            terms. In case of an organization, company or branch of
            government, you represent and warrant that you have the
            authority to legally bind your company or organization and your
            company or organization will be bound by the obligations and
            restrictions of these terms. any and all references herein to
            'You' or 'Your' will include your company or organization. If
            you do not agree to the terms or do not have the authority to
            bind your organization or company to these terms, then do not
            access, avail, download or use the Venus Browser.it is
            understood that this terms and conditions shall construe an
            agreement between you and BlueSky Inventions 1. About The Apps
            Venus Browser is developed by BlueSky Inventions that allows
            Users to view and use News, commerce, Music, Video, Games, and
            Cricket, etc., aggregated from different sources (hereinafter
            referred to as 'Contents'). This Browser uses an intermediary
            and/or enabling technology that categorizes Contents from
            various sources and allows Users to explore, search access, view
            and share the Contents with third parties based on these
            categories. 2. Eligibility Use and access to the Browser is
            available to all persons who can be legally bound by contract
            and who are not disqualified or declared incompetent under the
            Indian Contract Act, 1872. If You are a minor i.e. under the age
            of 18 years, You shall not register as a User of the Browser and
            shall not access or use the Browser. As a minor if You wish to
            access or use the Browser, such access or use may be made by
            Your legal guardian or parents on the Browser. BlueSky
            Inventions reserves the right to terminate such use and/ or
            refuse to provide You with access to the Browser if it is
            brought to BlueSky Invention’s notice or if it is discovered
            that You are under the age of 18 years. 3. Term and Termination
            These Terms, and any posted revision, shall remain in full force
            and effect while you use the Browser and certain provisions may
            continue to apply even after termination. You may terminate this
            Agreement by uninstalling or deleting the Browser at any time,
            for any reason. BlueSky Inventions may terminate this Agreement
            by deleting Your account or profile without assigning any
            reasons whatsoever. We reserve the right to change these Terms
            from time to time. If You do not agree to any such changes, You
            have the discretion to discontinue from accessing, availing or
            using the Browser. Continued access or use of the Browser
            following notice of any such changes will indicate Your
            acknowledgement of such changes and You will be bound by such
            revised Terms. 4. Communications When You use the Browser or
            send emails or other data, information or communication to
            BlueSky Inventions, You agree and understand that You are
            communicating with BlueSky Inventions through electronic records
            and You consent to receive communications via electronic records
            from BlueSky Inventions periodically and as and when required.
            BlueSky Inventions may communicate with You by email provided by
            You in Your communications or at the time of registration or by
            any other mode of communication, electronic or otherwise.
            However, BlueSky Inventions does not assure any confidentiality
            or security of information provided to BlueSky Inventions from
            Your side. 5. Your License and Use of the Browser Subject to the
            Terms and Conditions and Your payment of required fees, if any,
            BlueSky Inventions hereby grants You only a limited,
            non-transferrable, non-exclusive, revocable license to make
            personal and non-commercial use of the Browser. The Browser is
            licensed, not sold, to You, and BlueSky Inventions retains
            ownership of all copies of the Browser even after installation
            on Your devices. BlueSky Inventions may assign this License
            Agreement or any part of it without restrictions. You are not
            allowed to assign, transfer or sub-license Your rights under
            this license, to any third party. 6. Proprietary Rights In
            Contents BlueSky Inventions holds no responsibility for the
            materials contained in the Contents or in accuracy of the
            Contents accessible by use of the Browser by the User. You
            acknowledge that the Browser is merely an intermediary/ enabling
            technology that allows Users to search for Contents. In no event
            shall BlueSky Inventions assume or have any responsibility or
            liability for the Contents accessible by use of the Browser for
            any claims, damages or losses resulting from the use of the
            Contents. 7. Activity Prohibited You agree, undertake and
            confirm that Your use of the Browser shall be strictly governed
            by, including but not limited to the following: i. You shall not
            alter or modify any part of the Browser and/or use the Browser
            for any illegal purpose. ii. You shall not reformat or frame any
            portion of any web page that is part of the Browser. iii. You
            shall not collect or harvest or attempt to collect personal
            data, or any other kind of information about other Users,
            including without limitation, through spidering or any form of
            scraping. iv. You shall not post advertisements, promotions or
            solicitations of business or solicit Users or post spam or any
            other form of solicitation or spam; post or transmit any
            communication or solicitation designed or intended to obtain the
            password, account or private information from any other User. v.
            You shall not deliberately impersonate another person, whether
            real or fictional or otherwise misrepresent Your affiliation
            with a person or entity, for example, by registering an account
            in the name of another person or company, or sending messages or
            making comments using the name of another person impersonate
            other Users or otherwise fake Your identity. vi. You shall not
            rent, sell or transfer or lease or offer to sell or transfer
            access to the Browser and/or any BlueSky Inventions account or
            permit any third parties to use Your name and password, or any
            Contents on the Browser. vii. You shall not resell or
            commercially use the Browser or any of its Contents, or download
            or copy account information for the benefit of Yourself or any
            third-party. The Browser is for personal use only and may not be
            reproduced, duplicated, copied, sold, resold, visited, or
            otherwise exploited for any commercial purpose. viii. You shall
            not authorize any third party to use Your account. ix. You shall
            not commit or engage in, or encourage, induce, solicit or
            promote, any conduct that would constitute a criminal offence,
            give rise to civil liability or otherwise violate any law or
            regulation; or use the Browser for any illegal or unauthorized
            purpose; You agree to comply with all local laws applicable to
            Your conduct and the content and information, including
            hyperlinks, that You upload, store, share or transmit using the
            Browser. x. You shall not alter or remove, attempt to alter or
            remove any trademark, copyright or other proprietary or legal
            notices contained in, or appearing on, the Browser or on any
            Contents appearing on the Browser. You may not frame or utilize
            framing techniques to enclose any trademark, logo, or other
            proprietary information (including images, content, music,
            video, text, page layout, or form) of BlueSky Inventions and our
            affiliates or other Users. You may not use any meta-tags or any
            other "hidden text" utilizing BlueSky Invention’s name or
            trademarks without the express written consent of BlueSky
            Inventions. Any unauthorized use terminates the permission or
            license granted by BlueSky Inventions. You may not use any
            BlueSky Inventions logo or other proprietary graphic or
            trademark as part of the link without BlueSky Invention’s
            advance express written permission. xi. You shall not employ
            scraping or similar techniques to aggregate, repurpose, adapt,
            copy, republish, make available or otherwise communicate to the
            public, display, perform, transfer, share, distribute or
            otherwise use or exploit the Contents except via the features
            provided in the Browser. xii. You shall not, permit any third
            party to, copy or adapt the object code of the Browser, or
            reverse engineer, reverse assemble, decompile, modify or attempt
            to discover any source or object code of any part of the
            Browser, or circumvent or attempt to circumvent or copy any copy
            protection mechanism or access any rights management information
            pertaining to Contents. xiii. You shall not transmit any
            viruses, worms, defects, Trojan horse, cancel bots, spyware,
            other items of a contaminating or destructive nature, adware,
            packet or IP spoofing, forged routing or electronic mail address
            information or similar methods or technology harmful code, flood
            pings, malware, bot, time bomb, worm, or other harmful or
            malicious component, which or might overburden, impair or
            disrupt the Browser or networks forming part of, or connected
            to, the Browser, or which does or might restrict or inhibit any
            other User's use and enjoyment of the Browser. xiv. You shall
            not stalk, exploit, threaten, abuse or otherwise harass another
            User, or any BlueSky Inventions employee. xv. You shall not
            violate, circumvent or attempt to violate or circumvent any data
            security measures employed by BlueSky Inventions; access or
            attempt to access data or materials which are not intended for
            Your use; log into, or attempt to log into, an account which You
            are not authorized to access; attempt to scan or test the
            vulnerability of BlueSky Invention’s server, system or network
            or attempt to breach BlueSky Invention’s data security or
            authentication procedures; attempt to interfere with the Browser
            by any means including, without limitation, hacking BlueSky
            Invention’s servers or systems, submitting a virus, overloading,
            mail-bombing or crashing. insult, harass, threaten, molest or
            intimidate other Users. xvi. You shall not use any robot,
            spider, offline readers, site search and/or retrieval
            application, or other device to retrieve or index any portion of
            the Browser, with the exception of public search engines; use
            any robot, spider, scraper or other automated means to access,
            analyze or copy the Browser and/or information (whether our
            information or other User's information. xvii. You shall at all
            times ensure full compliance with the applicable provisions of
            the Information Technology Act, 2000 and rules there under as
            applicable and as amended from time to time and also all
            applicable Domestic laws, rules and regulations (including the
            provisions of any applicable Exchange Control Laws or
            Regulations in Force) and International Laws, Foreign Exchange
            Laws, Statutes, Ordinances and Regulations (including, but not
            limited to Sales Tax/VAT, Income Tax, Service Tax, Central
            Excise, Custom Duty, Local Levies. xviii. You agree to comply
            with the above conditions and acknowledge that BlueSky
            Inventions has the right, in its sole discretion, to terminate
            Your account or take action as in our sole discretion is
            necessary if You breach any of the above conditions or any of
            the other provisions of this Terms. xix. You understand that we
            have the right at all times to disclose any information
            (including the identity of the persons providing information or
            materials on Browser) as necessary to satisfy any law,
            regulation or valid governmental request. This may include,
            without limitation, disclosure of the information in connection
            with investigation of alleged illegal activity or solicitation
            of illegal activity or in response to a lawful court order. In
            addition, we can (and You hereby expressly authorize us to)
            disclose any information about You to law enforcement or other
            government officials, as we, in our sole discretion, believe
            necessary or appropriate in connection with the investigation
            and/or resolution of possible crimes, especially those that may
            involve personal injury; Throughout these Terms, BlueSky
            Invention’s prior written consent means a communication coming
            from BlueSky Invention’s Legal Department, specifically in
            response to Your request, and specifically addressing the
            activity or conduct for which You seek authorization. 8. Third
            Party Contents The Browser may provide access to third party
            websites that are not owned or controlled by BlueSky Inventions.
            BlueSky Inventions is not responsible for any third-party
            contents, applications, services, advertisements, and/or links
            that may be contained in the Browser. No part of the Browser and
            no Content may be copied, reproduced, republished, uploaded,
            posted, publicly displayed, encoded, translated, transmitted,
            downloaded or distributed in any way (including "mirroring") to
            any other computer, server, website or other medium for
            publication or distribution or for any commercial enterprise.
            BlueSky Inventions shall have all the rights to take necessary
            action and claim damage in case of any violation. Such action
            may also involve terminating of the permission for User to use
            the Browser by deleting User Account. You may use general
            information about the Browser expressly permitted, provided that
            You (1) do not remove any proprietary notice language in all
            copies of such documents, (2) use such Contents only for Your
            personal, non-commercial informational purpose unless agreed
            otherwise through an agreement and do not copy or post such
            Contents on any networked computer or broadcast it in any media,
            (3) make no modifications to any such Contents, and (4) do not
            make any additional representations or warranties relating to
            such documents. 9. User Representations and Warranties This
            Browser and the Contents under it are provided by BlueSky
            Inventions "as is" and on an "as available" basis and BlueSky
            Inventions, its officers, directors, employees, and agents make
            no warranties and hereby disclaim any express or implied
            warranties, including, but not limited to, the implied
            warranties (collectively, "warranties") of merchantability and
            fitness for a particular purpose are disclaimed. In no event
            shall BlueSky Inventions be liable for any direct, indirect,
            incidental, special, exemplary, or consequential damages
            (including, but not limited to, procurement of substitute goods
            or services; loss of use, data, or profits; or business
            interruption) however caused and on any theory of liability,
            whether in contract, strict liability, or tort (including
            negligence or otherwise) arising in any way out of the use of
            this Browser, even if advised of the possibility of such damage.
            You agree that Your use of the Browser shall be at Your sole
            risk. To the fullest extent permitted by law, BlueSky Inventions
            disclaims all warranties, express or implied, in connection with
            the use of the Browser. BlueSky Inventions makes no
            representations or guarantee that the Browser will be free from
            loss, destruction, damage, any unauthorized access to or use of
            BlueSky Invention’s secure servers and/or any and all personal
            information and/or financial information stored therein,
            corruption, attack, any interruption or cessation of
            transmission to or from the Browser, any bugs, viruses, Trojan
            horses, or the like which may be transmitted to or through the
            Browser by any third party, and/or any errors or omissions in
            any content or for any loss or damage of any kind incurred as a
            result of the use of any of the Content accessible via the
            Browser. BlueSky Inventions does not warrant, endorse,
            guarantee, or assume responsibility for any product or service
            advertised or offered by a third party through the services or
            any hyperlinked services or featured in any banner or other
            advertising, and BlueSky Inventions will not be a party to or in
            any way be responsible for monitoring any transaction between
            You and third-party providers of products or services. As with
            the purchase of a product or service through any medium or in
            any environment, You should use Your best judgment and exercise
            caution, interference, personal injury or property damage, of
            any nature whatsoever, resulting from Your access to and use of
            the Browser, hacking, or other security intrusion, and BlueSky
            Inventions disclaims any liability relating thereto. BlueSky
            Inventions makes no guarantees, representations, or warranties
            that use or results of the use of the Venus Browser will be
            accurate, timely, reliable, uninterrupted, or without errors.
            Without prior notice, BlueSky Inventions may modify, suspend, or
            discontinue any part or all of the Browser or Your use of the
            Browser. In such event, BlueSky Inventions will not be liable to
            You or any third party. BlueSky Inventions makes no guarantees,
            representations, or warranties that Contents accessible through
            the Browser by the User or the links provided by third parties
            will be free of viruses or similar contamination or destructive
            features. You agree that You assume all risk as to the quality
            and performance of the Browser and the accuracy and completeness
            of the contents. You understand that You may encounter
            offensive, indecent, or other objectionable content while using
            the Browser and that You may be involuntarily exposed to such
            offensive and obscene materials. It also is possible for others
            to obtain personal information about You due to Your use of the
            Browser, and that the recipient may use such information to
            harass or injure you. We do not approve of such unauthorized
            use, but by using the Browser You acknowledge and agree that we
            are not responsible for any such illegitimate use of any
            personal information so obtained by others. 10. Privacy BlueSky
            Inventions may collect some of Your personal information and
            data while accessing, availing and/or using the Browser. Such
            information collected is only attributed to the functionality of
            the Browser and for no other purpose whatsoever. You may read
            the BlueSky Invention’s Privacy Policy by visiting the following
            link here http://blueskyinvent.com/privacy 11. Disclaimers The
            Venus Browser, its features and contents are provided "As Is"
            and "As Available", and "With All Faults”. Your correspondence
            or business dealings with, or participation in promotions of,
            advertisers found on or through the Browser, including payment
            and delivery of related products or Services, and any other
            terms, conditions, warranties or representations associated with
            such dealings, are solely between You and such advertiser. We
            shall not be responsible or liable for any loss or damage of any
            sort incurred as the result of any such dealings or as the
            result of the presence of such advertisers on the Browser.
            BlueSky Inventions shall have all the rights to take necessary
            action and claim damages that may occur due to Your
            involvement/participation in any way on Your own or through
            group/s of people, intentionally or unintentionally in DoS/DDoS
            (Distributed Denial of Services). 12. Intellectual Property
            Rights The copyright, trademark, patent or other intellectual
            property rights in the Contents of the Browser (including,
            without limitation, all designs, logos, names, text code,
            processes, data, information links) are owned by BlueSky
            Inventions or the respective third party entities as identified
            in the Browser. No license or right is granted and Your use of
            and/or the Browser therein shall not constitute by implication,
            estoppel or otherwise, any license or right of use. As such, You
            shall not reproduce, transmit, republish, upload, post, perform,
            broadcast, adapt, parody, distribute, display, license and/or
            alter in whole or in part any of the foregoing in any manner
            without the express permission from BlueSky Inventions. You
            shall not download or encourage others to download copyrighted
            works, trademarks, or other proprietary information without
            obtaining the prior written consent of the owner of the
            Contents. In the event of infringement BlueSky Inventions shall
            on its own sole discretion take necessary steps. 13. Indemnity
            User hereby agrees to indemnify, defend and hold BlueSky
            Inventions, (including all its officers, directors and
            employees) harmless from and against any and all damages,
            liabilities, costs and expenses, including attorney's fees and
            expenses, arising out of, incident to, or resulting directly or
            indirectly from the use of the Browser in a manner inconsistent
            with this Terms. Notwithstanding its reasonable efforts, BlueSky
            Inventions cannot take responsibility or control the contents
            made for access through the Browser. 14. No Liability To the
            maximum extent permitted by applicable law, in no event shall
            BlueSky Inventions be liable for any direct, indirect,
            incidental, punitive, special or consequential damages, for loss
            of profits or confidential or other information, for business
            interruption, for personal injury, for loss of privacy, for
            failure to meet any duty including of good faith or of
            reasonable care, for negligence, and for any other pecuniary or
            other loss whatsoever arising out of or in any way related to
            the use or inability to use the Venus Browser and any loss or
            damage arising out of or in relation to acts of god or act of
            third party that is beyond the control of BlueSky Inventions.
            BlueSky Inventions shall not be liable for any direct or
            indirect damage for any data provided by the user or any
            violation of third party rights of whatsoever nature, arising
            out of or in any manner related to the use or inability to use
            the Venus Browser. 15. Law, Jurisdiction and Arbitration The
            construction of these Terms shall be determined in accordance
            with laws and rules in force in India and shall be subject to
            the exclusive jurisdiction of the Courts in Bangalore and no
            other court. 16. Severability If any provision in the Terms
            become invalid or illegal or adjudged unenforceable, the
            provision shall be deemed to have been severed from the Terms
            and the remaining provisions of the Terms shall not, so far as
            possible, be affected by the severance. 17. Waiver The failure
            of BlueSky Inventions to enforce at any time any of the
            provisions of this Terms shall not be construed to be a waiver
            of its right, power, privilege or remedy or as a waiver of any
            preceding or succeeding breach on Your part to this Terms nor
            shall any single or partial exercise of any right power
            privilege or remedy preclude any other or further exercise of
            such or any other right power privilege or remedy provided in
            this Terms all of which are several and cumulative and are not
            exclusive of each other or of any other rights or remedies
            otherwise available to BlueSky Inventions at law or in equity.
            18. Force Majeure and Act of Third Parties The performance of
            any part of this Terms by BlueSky Inventions shall be excused to
            the extent that such performance is hindered, delayed, or made
            impractical by flood, fire, war, or riot or any other cause
            beyond the reasonable control of BlueSky Inventions or act of
            any third party beyond the control of BlueSky Inventions
            including but not limited to hacking, data theft, unauthorised
            access to Your account, impersonation, fraud, misrepresentation
            and so on. 19. Modification BlueSky Inventions can update,
            modify, suspend, discontinue or change any part of these Terms
            from time to time The revised Terms will be posted as
            notification here on this link http://blueskyinvent.com/privacy.
            Users are encouraged to periodically check this page to stay
            informed about changes to the Terms. The revised Terms will be
            effective from the date stated on the revised Terms. You
            acknowledge and agree that it is Your responsibility to review
            these Terms periodically and become aware of modifications. If
            You disagree to any of the changes to the Terms, please refrain
            from accessing or using the Browser. Your continued access or
            use or availing of the Browser following the posting of revised
            Terms will indicate Your acceptance and acknowledgement of the
            changes and You will be bound by it. 20. Survival These Terms,
            and any modifications, alterations or amendments to, shall
            remain in full force and effect while You use the Browser and
            the provisions related to Intellectual Property, Warranties and
            Indemnity shall continue to apply even after termination of this
            Agreement. 21. Notices and Take Down Policy Notice is
            specifically given that BlueSky Inventions is not responsible
            for the News Content or Music content or Video content or
            commerce content or gaming content or Advertisements accessible
            through the Browser. BlueSky Inventions reserves right in its
            sole discretion to remove and/or disable access to any Content
            claimed to be a copyright infringement and/or terminate the
            accounts of the Users of the Browser who may infringe upon the
            copyrights or other intellectual property rights of BlueSky
            Inventions and/or others. All notices to BlueSky Inventions
            hereunder shall be in writing and shall be duly given if
            delivered personally or sent by registered mail, return receipt
            requested, or facsimile to to our Grievance Officer at:
            reachus@blueskyinvent.com or write to us at: BlueSky Inventions,
            #634, 4th Main, OMBR layout, Banaswadi, Bangalore, Karnataka
            560043. This document is an electronic record in terms of
            Information Technology Act, 2000 and rules there under as
            applicable and the amended provisions pertaining to electronic
            records in various statutes as amended by the Information
            Technology Act, 2000. This electronic record is generated by a
            computer system and does not require any physical or digital
            signatures. This document is published in accordance with the
            provisions of Rule 3 (1) of the Information Technology
            (Intermediaries guidelines) Rules, 2011 that require publishing
            the rules and regulations, Privacy Policy and Terms for access
            or usage of the Browser. I have read and understood the Terms
            and Conditions and I hereby, out of my free will,
            unconditionally accept to be bound by the same. USER
            <br />
            <br />
            We have a vast understanding of AWS, Azure and Google Cloud of
            how they work and how we can implement them in our projects.
          </div>
        </div>
      </div>
    </section>
  </Container>
  )
}

export default PrivacyPage