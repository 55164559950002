import React from "react";
import linkedin from "../../assets/utility_images/linkedin-leader.svg";

import "./LeaderCard.css";

const LeaderCard = ({background,heading,description,role,externalUrl}) => {
  return (
    <div className="leader-card d-flex flex-column align-items-center">
      <div className="leader-photo d-flex align-items-center justify-content-center">
        <img src={background} alt="" />
      </div>
      <div className="leader-name">{heading}</div>
      <div className="leader-company">{role}</div>
      <div className="leader-experience">
        {description}
      </div>
      <a href={externalUrl} target="_blank" rel="noreferrer">
        <div className="linkedIn">
          <img src={linkedin} alt="" />
        </div>
      </a>
    </div>
  );
};

export default LeaderCard;
