import React from "react";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import { NavLink } from "react-router-dom"; // Using NavLink
import "./NavbarComp.css";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../components/Loader/Loader";
import { useLanguage } from "../../Context/LanguageContext";
import fallbackDataNavbarEN from "../../FallbackAPI/NavbarLocal/FallbackApiNavbarEN.json";
import fallbackDataNavbarKR from "../../FallbackAPI/NavbarLocal/FallbackApiNavbarKR.json";
import { useLocation } from "react-router-dom";

const NavbarComp = () => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const [toggleMenu, setToggleMenu] = React.useState(false);
  const { language, setLanguage } = useLanguage();
  const location = useLocation();

  const isActive = (path) => {
      if (path === "/careers") {
        return (
          location.pathname.startsWith("/careers") ||
          location.pathname.startsWith("/jobs")
        );
      }
      return location.pathname.startsWith(path);
  };

  const {
    data: apiData,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["navbarData", language],
    queryFn: async () => {
      try {
        const response = await fetch(`${baseUrl}/navbar/${language}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch careers data");
        }
        return response.json();
      } catch (error) {
        console.warn("API request failed, using fallback data.");
        return language === "en" ? fallbackDataNavbarEN : fallbackDataNavbarKR;
      }
    },
    staleTime: 10 * 60 * 1000,
    cacheTime: 60 * 60 * 1000,
    retry: 2,
    refetchOnWindowFocus: false,
  });

  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage);
    setToggleMenu(false); // Close the menu when language is changed
  };

  const getLanguageClass = (lang) =>
    language === lang ? "language-toggler active" : "language-toggler";

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error loading data.</div>;
  }

  const Menu = () => (
    <>
      {apiData &&
        apiData.pageLinks
          ?.filter((link) => link.isEnabled)
          .sort((a, b) => a.order - b.order)
          .map((link) => (
            <li key={link.id} className="nav-item">
              <NavLink
                to={link.url}
                className={
                  isActive(link.url)
                    ? "nav-link nav-active navlink-ltr"
                    : "nav-link nav-link-ltr"
                }
              >
                {link.pageName}
              </NavLink>
            </li>
          ))}
    </>
  );

  return (
    <div className="navbar fixed-top">
      <NavLink className="navbar-logo" to="/">
        {apiData.logoUrl ? (
          <img src={apiData.logoUrl} alt="logo" />
        ) : (
          <div></div>
        )}
      </NavLink>
      <div className="navbar-links-container d-flex align-items-center">
        <div className="links">
          <Menu />
        </div>
        <div className="language-selector">
          <div className="language">
            <span
              className={getLanguageClass("ko-KR")}
              onClick={() => handleLanguageChange("ko-KR")}
            >
              kor
            </span>
          </div>
          <div className="vertical-line"></div>
          <div className="language">
            <span
              className={getLanguageClass("en")}
              onClick={() => handleLanguageChange("en")}
            >
              eng
            </span>
          </div>
        </div>
      </div>

      <div className="navbar-menu">
        {toggleMenu ? (
          <RiCloseLine
            color="black"
            size={25}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color="black"
            size={25}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="navbar-menu-container scale-up-center">
            <div className="navbar-menu-container-links">
              <Menu />
            </div>
            <div className="language-selector">
              <div className="language">
                <span
                  className={getLanguageClass("ko-KR")}
                  onClick={() => handleLanguageChange("ko-KR")}
                >
                  kor
                </span>
              </div>
              <div className="vertical-line"></div>
              <div className="language">
                <span
                  className={getLanguageClass("en")}
                  onClick={() => handleLanguageChange("en")}
                >
                  eng
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavbarComp;
