import React, { useState, useRef } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import emailjs from "emailjs-com";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the CSS for React Toastify

const JobModal = ({ show, handleClose }) => {
  const formRef = useRef(null); // Create a reference for the form

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    location: "", // Initialize 'location' here
    file: null, // To store the uploaded file
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handlePhoneChange = (phone) => {
    setFormData((prev) => ({ ...prev, phone }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0]; // Get the first file selected
    setFormData((prev) => ({ ...prev, file }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Send email using EmailJS and the form reference
    emailjs
      .sendForm("srajan2303", "srajan23", formRef.current, "lWzokgQj4V54uZqiu")
      .then(
        (response) => {
          toast.success("Your resume is submitted!"); // Show success toast
        },
        (err) => {
          toast.error("Failed to send your resume. Please try again."); // Show error toast
        }
      )
      .finally(() => {
        // Reset form data
        setFormData({
          name: "",
          email: "",
          phone: "",
          location: "", // Reset location
          file: null, // Reset file
        });
        handleClose(); // Close the contact modal
      });
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Apply Now</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Form.Group controlId="formName">
              <Form.Label>
                Full Name <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter your Full Name"
                required
              />
            </Form.Group>

            <Form.Group controlId="formEmail" className="mt-3">
              <Form.Label>
                Email <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email"
                required
              />
            </Form.Group>

            <Form.Group controlId="formPhone" className="mt-3">
              <Form.Label>
                Phone Number <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <PhoneInput
                country={"in"}
                value={formData.phone}
                onChange={handlePhoneChange}
                inputStyle={{ width: "100%" }}
                required
                placeholder="Enter your phone number"
              />
            </Form.Group>

            <Form.Group controlId="formLocation" className="mt-3">
              <Form.Label>
                Current Location <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="location"
                value={formData.location}
                onChange={handleChange}
                placeholder="Enter your current location"
                required
              />
            </Form.Group>

            {/* File Upload Field */}
            <Form.Group controlId="formFile" className="mt-3">
              <Form.Label>
                Resume/CV<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="file"
                name="file"
                onChange={handleFileChange}
                accept=".pdf,.doc,.docx"
                required
              />
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-3">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
    </>
  );
};

export default JobModal;
