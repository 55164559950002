import React,{useState} from "react";
import "./DiscoverMorePage.css";
import offeringsintro from "../../assets/utility_images/offerings.jpeg";
import { Row, Col, Container } from "react-bootstrap";
import ourofferings from "../../assets/utility_images/ourofferings.svg";
import arrowRight from "../../assets/utility_images/arrow-right.svg";
import { Link as ScrollLink } from "react-scroll";
import { useParams } from "react-router-dom";
import { useLanguage } from "../../Context/LanguageContext";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../components/Loader/Loader";


const DiscoverMorePage = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { cardName } = useParams();
  const { language } = useLanguage();
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const {
    data: DiscoverMoreApiData,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["DiscoverMore", cardName, language],
    queryFn: async () => {
      const response = await fetch(`${baseUrl}/page/${cardName}/${language}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch job details");
      }
      return response.json();
    },
    staleTime: 10 * 60 * 1000,
    cacheTime: 60 * 60 * 1000,
  });

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error loading data.</div>;
  }

  return (
    <div>
      <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
        <section className="Hero">
          <div className="hero-section">
            <div className="hero-banner d-flex">
              <div className="background-hero d-flex justify-content-start align-items-center">
                <img src={ourofferings} alt="" />
                <div className="hero-description d-flex flex-column">
                  <div className="hero-heading">
                    <span>클라우드 컴퓨팅</span>
                    <br />
                    <span> </span>
                  </div>
                  <div className="hero-paragraph">
                    <p>
                      We help our clients in integrating cloud platforms to
                      their projects
                    </p>
                  </div>
                  <ScrollLink
                    to="intro-offering"
                    smooth={true}
                    duration={500}
                    offset={-50}
                    className="linkToPage"
                  >
                    {" "}
                    <button className="btn-hero d-flex justify-content-evenly align-items-center">
                      know more
                      <div className="hero-btn">
                        <img src={arrowRight} alt="" />
                      </div>
                    </button>
                  </ScrollLink>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Container>

      <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
        <section className="page-template-section">
          <div className="page-intro" id="intro-offering">
            <div className="page-intro-content d-flex">
              <div className="page-intro-img">
                <img src={offeringsintro} alt="" />
              </div>

              <div className="page-intro-description">
                {" "}
                <div className="heading-container d-flex flex-column align-items-start">
                  <div className="section-heading d-flex align-items-center">
                    <div className="line"></div>
                    <div className="section-title">Cloud Computing</div>
                  </div>
                  <div className="value-header">
                    Our Knowledge in Cloud Computing
                  </div>
                  <div className="intro-description">
                    Cloud computing is the on-demand availability of computer
                    system resources, especially data storage (cloud storage)
                    and computing power, without direct active management by the
                    user. Large clouds often have functions distributed over
                    multiple locations, each of which is a data center. Cloud
                    computing relies on sharing of resources to achieve
                    coherence and typically uses a pay-as-you-go model, which
                    can help in reducing capital expenses but may also lead to
                    unexpected operating expenses for users.
                    <br />
                    <br />
                    We have a vast understanding of AWS, Azure and Google Cloud
                    of how they work and how we can implement them in our
                    projects.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Container>
    </div>
  );
};

export default DiscoverMorePage;
