import React from "react";
import "./ServiceCard.css";

const ServiceCard = ({ image, heading, description }) => {
  return (
    <div className="service-card d-flex flex-column align-items-center justify-content-start">
      <div className="service-card-image">
        <img src={image} alt="" />
      </div>
      <div className="service-card-content d-flex flex-column align-items-center">
        <div className="service-heading">{heading}</div>
        <div className="service-desc">{description}</div>
      </div>
    </div>
  );
};

export default ServiceCard;
