import React from "react";
import "./FooterComp.css";
import { Link, NavLink } from "react-router-dom";
import phoneicon from "../../assets/utility_images/phoneicon.svg";
import mailicon from "../../assets/utility_images/mailicon.svg";
import location from "../../assets/utility_images/locationicon.svg";
import arrowright from "../../assets/utility_images/angularright.svg";
import { Row, Col, Container } from "react-bootstrap";
import { useLanguage } from "../../Context/LanguageContext";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../components/Loader/Loader";
import fallbackDataFooterEN from "../../FallbackAPI/FooterLocal/FallbackApiFooterEN.json";
import fallbackDataFooterKR from "../../FallbackAPI/FooterLocal/FallbackApiFooterKR.json";

const FooterComp = () => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { language, t } = useLanguage(); // Access the translation object
  // console.log(t);
  const {
    data: apiData,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["footerData", language],
    queryFn: async () => {
      try {
        const response = await fetch(`${baseUrl}/footer/${language}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch careers data");
        }
        return response.json();
      } catch (error) {
        console.warn("API request failed, using fallback data.");
        return language === "en"
          ? fallbackDataFooterEN
          : fallbackDataFooterKR;
      }
    },
    staleTime: 10 * 60 * 1000, // Cache data for 10 minute before considering it stale
    cacheTime: 60 * 60 * 1000, // Keep data in cache for 1 hr after becoming stale
    retry: 2, // Retry once on failure
    refetchOnWindowFocus: false, // Prevent refetch on window focus
    onError: (error) => {
      console.error("Error fetching data:", error);
    },
  });

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error loading footer data.</div>;
  }

  if (!apiData || !apiData.isEnabled) {
    return null; // Do not render the footer if isEnabled is false
  }

  const filteredPageLinks = apiData.pageLinks
    ?.filter((link) => link.isEnabled)
    .sort((a, b) => a.order - b.order);

  const filteredOffices = apiData.offices
    ?.filter((office) => office.isEnabled)
    .sort((a, b) => a.order - b.order);

  const filteredSocialLinks = apiData.socialLinks
    ?.filter((social) => social.isEnabled)
    .sort((a, b) => a.order - b.order);

  return (
    <Container fluid className="footer">
      <div className="footer-content d-flex flex-column">
        <Row
          xs={12}
          sm={2}
          md={2}
          lg={4}
          xl={4}
          className="custom-padding-footer"
        >
          <Col className="pb-lg-5 pb-md-5 pb-5 pb-xl-0">
            {apiData.logoUrl && apiData.about && (
              <div className="footer-col1 d-flex flex-column">
                <Link className="footer-logo" to="/">
                  <img src={apiData.logoUrl} alt="logo" />
                </Link>
                <div className="footer-desc-heading d-flex flex-column">
                  {t.aboutUs}{" "}
                  {/* Render "About Us" based on the selected language */}
                </div>
                <div className="footer-desc">{apiData.about}</div>
              </div>
            )}
          </Col>

          {/* Quick Links Section */}
          <Col className="ps-xl-5 ps-lg-5 ps-md-5 ps-sm-5 ps-xs-3 pb-lg-5 pb-md-5 pb-5 pb-xl-0">
            {filteredPageLinks?.length > 0 && (
              <div className="footer-col1 d-flex flex-column">
                <div className="footer-desc-heading d-flex flex-column">
                  {t.quickLinks}{" "}
                  {/* Render "Quick Links" based on the selected language */}
                </div>
                <div className="links-list">
                  {filteredPageLinks.map((link) => (
                    <li
                      key={link.id}
                      className="list-items-footer d-flex align-items-center"
                    >
                      <div className="arrow-right">
                        <img src={arrowright} alt="" />
                      </div>
                      <NavLink
                        to={link.url}
                        className={({ isActive }) =>
                          isActive ? "quick-link active-link" : "quick-link"
                        }
                      >
                        {link.pageName}
                      </NavLink>
                    </li>
                  ))}
                </div>
              </div>
            )}
          </Col>

          {/* Offices and Contact sections similarly use translations */}
          <Col className="ps-xl-5 pb-md-5 pb-lg-0 pb-xl-0">
            {filteredOffices?.length > 0 && (
              <div className="footer-col1 d-flex flex-column">
                <div className="footer-desc-heading d-flex flex-column">
                  {t.ourOffices}{" "}
                  {/* Render "Our Offices" based on the selected language */}
                </div>
                <div className="links-list">
                  {filteredOffices.map((office) => (
                    <li
                      key={office.id}
                      className="list-items-footer d-flex align-items-center"
                    >
                      <div className="location-icon">
                        <img src={location} alt="" />
                      </div>
                      <a
                        className="quick-link"
                        href={office.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {office.name}
                      </a>
                    </li>
                  ))}
                </div>
              </div>
            )}
          </Col>

          <Col className="ps-xl-5 ps-lg-5 ps-md-5 ps-sm-5 pb-lg-0 pb-md-5 pb-xl-0">
            {apiData.contactEmail || apiData.phoneNumber ? (
              <div className="footer-col1 d-flex flex-column">
                <div className="footer-desc-heading d-flex flex-column">
                  {t.contact}{" "}
                  {/* Render "Contact" based on the selected language */}
                </div>
                <div className="links-list">
                  {apiData.contactEmail && (
                    <li className="list-items-footer d-flex align-items-center">
                      <div className="contact-icon">
                        <img src={mailicon} alt="" />
                      </div>
                      <a
                        className="mail"
                        href="/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {apiData.contactEmail}
                      </a>
                    </li>
                  )}
                  {apiData.phoneNumber && (
                    <li className="list-items-footer d-flex align-items-center">
                      <div className="contact-icon">
                        <img src={phoneicon} alt="" />
                      </div>
                      <span className="phone-no">{apiData.phoneNumber}</span>
                    </li>
                  )}
                </div>

                {filteredSocialLinks?.length > 0 && (
                  <div className="social-handles d-flex gap-3">
                    {filteredSocialLinks.map((social) => (
                      <a
                        key={social.id}
                        href={social.url}
                        target="_blank"
                        rel="noreferrer"
                        className="quick-link"
                      >
                        <img src={social.iconUrl} alt={social.platform} />
                      </a>
                    ))}
                  </div>
                )}
              </div>
            ) : null}
          </Col>
        </Row>
        <div className="copyright">
          {" "}
          Copyright &copy; 2024 JKSEL. All rights reserved l{" "}
          <Link className="linkToPage" to="/privacy" target="_blank">
            Privacy 
          </Link>
          &nbsp;l{" "}
          <Link className="linkToPage" to="/privacy" target="_blank">
            Terms and Conditions
          </Link>
        </div>
        {/* {apiData.copyRightText && (
          <div className="copyright">{apiData.copyRightText}</div>
         
       
        )} */}
      </div>
    </Container>
  );
};

export default FooterComp;
