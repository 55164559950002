import React, { useState } from "react";
import ValueCard from "../../components/ValueCard/ValueCard";
import "./AboutUs.css";
import { Row, Col, Container } from "react-bootstrap";
import LeaderCard from "../../components/LeaderCard/LeaderCard";
import { useQuery } from "@tanstack/react-query";
import { useLanguage } from "../../Context/LanguageContext";
import arrowRight from "../../assets/utility_images/arrow-right.svg";
import ceobackground from "../../assets/utility_images/ceo-background.svg";
import corner1 from "../../assets/utility_images/corner1.svg";
import corner2 from "../../assets/utility_images/corner2.svg";
import { Link } from "react-router-dom";
import linkedinceo from "../../assets/utility_images/linkedin-ceo.svg";
import "react-phone-input-2/lib/style.css";
import ContactModal from "../../components/ContactModal/ContactModal";
import Loader from "../../components/Loader/Loader";
import fallbackDataAboutUsEN from "../../FallbackAPI/AboutUsLocal/FallbackApiAboutUsEN.json";
import fallbackDataAboutUsKR from "../../FallbackAPI/AboutUsLocal/FallbackApiAboutUsKR.json";

const AboutUs = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { language } = useLanguage();

  const {
    data: AboutUsApiData,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["AboutsUsData", language],
    queryFn: async () => {
      try {
        const response = await fetch(`${baseUrl}/page/AboutUs/${language}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch home page data");
        }
        return response.json();
      } catch (error) {
        console.warn("API request failed, using fallback data.");
        return language === "en"
          ? fallbackDataAboutUsEN
          : fallbackDataAboutUsKR; // Return fallback data when API fails
      }
    },
    staleTime: 10 * 60 * 1000, // Cache data for 10 minutes before considering it stale
    cacheTime: 60 * 60 * 1000, // Keep data in cache for 1 hr
    retry: 2, // Retry twice on failure
    refetchOnWindowFocus: false, // Prevent refetch on window focus
    onError: (error) => {
      console.error("Error fetching data:", error);
    },
  });

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error loading data.</div>;
  }

  const renderSection = (section) => {
    switch (section.sectionName) {
      case "AboutUsIntro":
        return (
          <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <section className="page-intro-section">
              <div className="page-intro ">
                <div className="corner-1">
                  <img src={corner1} alt="" />
                </div>
                <div className="page-intro-content d-flex">
                  <div className="page-intro-img">
                    <img src={section.imageUrl} alt="" />
                  </div>
                  <div className="page-intro-description">
                    <div className="heading-container d-flex flex-column align-items-start">
                      <div className="section-heading d-flex align-items-center">
                        <div className="line"></div>
                        <div className="section-title">{section.heading}</div>
                      </div>
                      <div className="value-header">{section.introText}</div>
                      <div className="intro-description">
                        {section.description
                          .split("<!--split-->") // Split at the specified delimiter
                          .map((paragraph, index) => (
                            <p key={index}>{paragraph}</p>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="corner-2">
                  <img src={corner2} alt="" />
                </div>
              </div>
            </section>
          </Container>
        );

      case "CEOSection":
        return (
          <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <section className="ceo">
              <div className="ceo-container d-flex">
                <div className="ceo-section1">
                  {" "}
                  <div className="heading-container d-flex flex-column align-items-start">
                    <div className="section-heading d-flex align-items-center">
                      <div className="line"></div>
                      <div className="section-title">{section.heading}</div>
                    </div>
                    <div className="ceo-header">{section.introText}</div>
                  </div>
                  <div className="ceo-content d-flex">
                    <div className="ceo-background d-flex">
                      <img className="ceo-bg" src={ceobackground} alt="" />
                      <div className="ceo-pic d-flex flex-column align-items-center ">
                        <div className="ceo-img">
                          <img src={section.imageUrl} alt="" />
                        </div>
                        <div className="linkedin">
                          <a
                            href="https://www.linkedin.com/in/dr-jason-kwak-bb897b9a/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {" "}
                            <img src={linkedinceo} alt="" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="ceo-information">
                      {" "}
                      {section.description
                        .split("<!--split-->") // Split at the specified delimiter
                        .map((paragraph, index) => (
                          <p key={index}>{paragraph}</p>
                        ))}
                    </div>
                  </div>
                </div>
                <div className="ceo-section2">
                  {section.sectionItems.map((experience, index) => (
                    <li className="ceo-list" key={index}>
                      <div className="ceo-name">{experience.title}</div>
                      <div className="details">
                        <div className="company-name">
                          {experience.companyOrAuthor}
                        </div>
                        <div className="work">{experience.quote}</div>
                        <div className="date">{experience.description}</div>
                      </div>
                    </li>
                  ))}
                </div>
              </div>
            </section>
          </Container>
        );

      case "ValuesSection":
        return (
          <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <section className="Value-section">
              <div className="values">
                <div className="heading-container d-flex flex-column align-items-start">
                  <div className="section-heading d-flex align-items-center">
                    <div className="line"></div>
                    <div className="section-title">{section.heading}</div>
                  </div>
                  <div className="value-header">{section.introText}</div>
                </div>
                <div className="value-container">
                  {" "}
                  <Row xs={1} sm={2} md={2} lg={2} xl={3}>
                    {section.sectionItems
                      .filter((item) => item.isEnabled) // Filter enabled items
                      .sort((a, b) => a.order - b.order) // Sort by order
                      .map((item, index) => (
                        <Col className="pb-xl-5 pb-lg-5 pb-md-3 pb-sm-3 pb-3">
                          <ValueCard
                            icon={item.imageUrl} // Pass icon from API or default
                            heading={item.title} // Pass heading from API
                            description={item.description} // Pass description from API
                          />
                        </Col>
                      ))}
                  </Row>
                </div>
              </div>
            </section>
          </Container>
        );

      case "LeadersSection":
        return (
          <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <section className="leaders-section">
              <div className="leaders d-flex flex-column">
                <div className="heading-container d-flex flex-column align-items-start">
                  <div className="section-heading d-flex align-items-center">
                    <div className="line"></div>
                    <div className="section-title">{section.heading}</div>
                  </div>
                  <div className="value-header">{section.introText}</div>
                </div>
                <div className="leader-container d-flex justify-content-evenly flex-wrap ">
                  {/* <Row md={3}> */}
                  {section.sectionItems
                    .filter((item) => item.isEnabled) // Filter enabled items
                    .sort((a, b) => a.order - b.order) // Sort by order
                    .map((item, index) => (
                      <div className="pb-xl-5 pb-lg-5 pb-md-5 pb-sm-5 pb-4">
                        <LeaderCard
                          background={item.imageUrl} // Pass icon from API or default
                          heading={item.title} // Pass heading from API
                          description={item.description} // Pass description from API
                          role={item.companyOrAuthor}
                          externalUrl={item.externalUrl}
                        />
                      </div>
                    ))}
                  {/* </Row> */}
                </div>
                <div className="join-button">
                  <Link className="linkToPage" to={section.buttonUrl}>
                    <button className="btn-leader d-flex justify-content-center align-items-center">
                      {section.buttonText}
                    </button>
                  </Link>
                </div>
              </div>
            </section>
          </Container>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {AboutUsApiData.viewPort.isEnabled && (
        <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
          <section className="Hero">
            <div className="hero-section">
              <div className="hero-banner d-flex">
                <div className="background-hero d-flex justify-content-start align-items-center">
                  <img
                    src={AboutUsApiData.viewPort.illustrationUrl}
                    alt="About Us"
                  />
                  <div className="hero-description d-flex flex-column">
                    <div className="hero-heading">
                      {/* Split the heading by a comma and join parts */}
                      {AboutUsApiData.viewPort.heading
                        .split(",")
                        .map((part, index) => (
                          <React.Fragment key={index}>
                            <span>{part.trim()}</span>
                            {/* Insert a <br /> after each part except the last one */}
                            {index !==
                              AboutUsApiData.viewPort.heading.split(",")
                                .length -
                                1 && <br />}
                          </React.Fragment>
                        ))}
                    </div>
                    <div className="hero-paragraph">
                      <p> {AboutUsApiData.viewPort.description}</p>
                    </div>

                    {/* Contact Us Button triggers modal */}
                    <button
                      className="btn-hero d-flex justify-content-evenly align-items-center"
                      onClick={handleShow} // Show modal on click
                    >
                      {AboutUsApiData.viewPort.buttonText}
                      <div className="hero-btn">
                        <img src={arrowRight} alt="Arrow" />
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Container>
      )}
      <ContactModal show={show} handleClose={handleClose} />

      {AboutUsApiData.sections
        .filter((section) => section.isEnabled) // Filter sections
        .sort((a, b) => a.sectionOrder - b.sectionOrder) // Sort sections by sectionOrder
        .map((section) => renderSection(section))}
    </div>
  );
};

export default AboutUs;
