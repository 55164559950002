import React, { useState, useEffect } from "react";
import "./TestimonialCarousel.css";
import leftArrow from "../../assets/utility_images/left.svg";
import rightArrow from "../../assets/utility_images/right.svg";
import Slider from "react-slick";

// Custom Arrow Components
const PrevArrow = ({ onClick }) => (
  <div className="slick-prev custom-arrow" onClick={onClick}>
    <img src={leftArrow} alt="Previous" />
  </div>
);

const NextArrow = ({ onClick }) => (
  <div className="slick-next custom-arrow" onClick={onClick}>
    <img src={rightArrow} alt="Next" />
  </div>
);

const TestimonialCarousel = ({ testimonials }) => {
  const [slidesToShow, setSlidesToShow] = useState(2);
  const [slidesToScroll, setSlidesToScroll] = useState(2);

  // Handle responsiveness for the number of slides shown
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1024) {
        setSlidesToShow(1);
        setSlidesToScroll(1);
      } else {
        setSlidesToShow(2);
        setSlidesToScroll(2);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const enabledTestimonials = testimonials
    .filter((testimonial) => testimonial.isEnabled) // Only include enabled testimonials
    .sort((a, b) => a.order - b.order); // Sort by order

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow,
    slidesToScroll,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {enabledTestimonials.map((testimonial, index) => (
          <div
            key={index}
            className={`slide d-flex flex-column ${
              index % 2 === 0 ? "light-bg" : "dark-bg"
            }`}
          >
            <img src={testimonial.imageUrl} alt="Quote Icon" />
            <div className="testimonial-text">{testimonial.quote}</div>
            <div className="testimony-name">{testimonial.title}</div>
            <div className="company">{testimonial.companyOrAuthor}</div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default TestimonialCarousel;
