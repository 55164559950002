import React from "react";
import "./ProductCard.css";

const ProductCard = ({ icon, heading, description }) => {
  return (
    <div className="product-card d-flex flex-column align-items-center">
      <div className="product-card-heading">{heading}</div>
      <div className="product-card-icon">
        <img src={icon} alt="" />
      </div>
      <div className="product-card-description">{description}</div>
    </div>
  );
};

export default ProductCard;
