import React, { useState } from "react";
import "./OurOfferings.css";
import { useLanguage } from "../../Context/LanguageContext";
import { useQuery } from "@tanstack/react-query";
import arrowRight from "../../assets/utility_images/arrow-right.svg";
import { Row, Col, Container } from "react-bootstrap";
import ProductCard from "../../components/ProductCard/ProductCard";
import ServiceCard from "../../components/ServiceCard/ServiceCard";
import corner1 from "../../assets/utility_images/corner1.svg";
import corner2 from "../../assets/utility_images/corner2.svg";
import offeringsintro from "../../assets/utility_images/offerings.jpeg";
import ContactModal from "../../components/ContactModal/ContactModal";
import Loader from "../../components/Loader/Loader";
import fallbackDataOurOfferingsEN from "../../FallbackAPI/OurOfferingsLocal/FallbackApiOurOfferingsEN.json";
import fallbackDataOurOfferingsKR from "../../FallbackAPI/OurOfferingsLocal/FallbackApiOurOfferingsKR.json";

const OurOfferings = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { language } = useLanguage();

  const {
    data: OurOfferingsApiData,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["OurOfferingsData", language],
    queryFn: async () => {
      try {
        const response = await fetch(
          `${baseUrl}/page/OurOfferings/${language}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch home page data");
        }
        return response.json();
      } catch (error) {
        console.warn("API request failed, using fallback data.");
        return language === "en"
          ? fallbackDataOurOfferingsEN
          : fallbackDataOurOfferingsKR; // Return fallback data when API fails
      }
    },
    staleTime: 10 * 60 * 1000, // Cache data for 10 minutes before considering it stale
    cacheTime: 60 * 60 * 1000, // Keep data in cache for 1 hr
    retry: 2, // Retry twice on failure
    refetchOnWindowFocus: false, // Prevent refetch on window focus
    onError: (error) => {
      console.error("Error fetching data:", error);
    },
  });

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error loading data.</div>;
  }

  const renderSection = (section) => {
    switch (section.sectionName) {
      
      case "OurOfferingsIntro":
        return (
          <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <section className="page-intro-section">
              <div className="page-intro ">
                <div className="corner-1">
                  <img src={corner1} alt="" />
                </div>
                <div className="page-intro-content d-flex">
                  <div className="page-intro-img">
                    <img src={offeringsintro} alt="" />
                  </div>

                  <div className="page-intro-description">
                    {" "}
                    <div className="heading-container d-flex flex-column align-items-start">
                      <div className="section-heading d-flex align-items-center">
                        <div className="line"></div>
                        <div className="section-title">{section.heading}</div>
                      </div>
                      <div className="value-header">{section.introText}</div>
                      <div className="intro-description">
                        {section.description}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="corner-2">
                  <img src={corner2} alt="" />
                </div>
              </div>
            </section>
          </Container>
        );

      case "ProductSection":
        return (
          <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <section className="product-section">
              <div className="product">
                <div className="heading-container d-flex flex-column align-items-start">
                  <div className="section-heading d-flex align-items-center">
                    <div className="line"></div>
                    <div className="section-title">{section.heading}</div>
                  </div>
                  <div className="pillar-header">{section.introText}</div>
                </div>
                <div className="product-container">
                  <Row xs={1} sm={1} md={2} lg={2}>
                    {section.sectionItems
                      .filter((item) => item.isEnabled) // Filter enabled items
                      .sort((a, b) => a.order - b.order) // Sort by order
                      .map((item, index) => (
                        <Col className="pb-5">
                          <ProductCard
                            icon={item.imageUrl}
                            heading={item.title}
                            description={item.description}
                          />
                        </Col>
                      ))}
                  </Row>
                </div>
              </div>
            </section>
          </Container>
        );

      case "ServiceSection":
        return (
          <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <section className="services-section">
              <div className="services">
                <div className="heading-container d-flex flex-column align-items-start">
                  <div className="section-heading d-flex align-items-center">
                    <div className="line"></div>
                    <div className="section-title">{section.heading}</div>
                  </div>
                  <div className="service-header">{section.introText}</div>
                </div>
                <div className="services-container">
                  <Row xs={1} sm={1} md={2} lg={2} xl={3}>
                    {section.sectionItems
                      .filter((item) => item.isEnabled) // Filter enabled items
                      .sort((a, b) => a.order - b.order) // Sort by order
                      .map((item) => (
                        <Col className="px-xl-4 pb-md-5 pb-sm-5 pb-4">
                          <ServiceCard
                            image={item.imageUrl} // Pass icon from API or default
                            heading={item.title} // Pass heading from API
                            description={item.description}
                          />
                        </Col>
                      ))}
                  </Row>
                </div>
              </div>
            </section>
          </Container>
        );

      default:
        return null;
    }
  };

  return (
    <div>
      {OurOfferingsApiData.viewPort.isEnabled && (
        <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
          <section className="Hero">
            <div className="hero-section">
              <div className="hero-banner d-flex">
                <div className="background-hero d-flex justify-content-start align-items-center">
                  <img
                    src={OurOfferingsApiData.viewPort.illustrationUrl}
                    alt="About Us"
                  />
                  <div className="hero-description d-flex flex-column">
                    <div className="hero-heading">
                      {OurOfferingsApiData.viewPort.heading
                        .split(",")
                        .map((part, index) => (
                          <React.Fragment key={index}>
                            <span>{part.trim()}</span>
                            {/* Insert a <br /> after each part except the last one */}
                            {index !==
                              OurOfferingsApiData.viewPort.heading.split(",")
                                .length -
                                1 && <br />}
                          </React.Fragment>
                        ))}
                    </div>
                    <div className="hero-paragraph">
                      <p> {OurOfferingsApiData.viewPort.description}</p>
                    </div>

                    {/* Contact Us Button triggers modal */}
                    <button
                      className="btn-hero d-flex justify-content-evenly align-items-center"
                      onClick={handleShow} // Show modal on click
                    >
                      {OurOfferingsApiData.viewPort.buttonText}
                      <div className="hero-btn">
                        <img src={arrowRight} alt="Arrow" />
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Container>
      )}
      <ContactModal show={show} handleClose={handleClose} />

      {OurOfferingsApiData.sections
        .filter((section) => section.isEnabled) // Filter sections
        .sort((a, b) => a.sectionOrder - b.sectionOrder) // Sort sections by sectionOrder
        .map((section) => renderSection(section))}
    </div>
  );
};

export default OurOfferings;
