import React, { createContext, useContext, useState, useEffect } from "react";

const LanguageContext = createContext();

const translations = {
  en: {
    quickLinks: "Quick Links",
    aboutUs: "About Us",
    ourOffices: "Our Offices",
    contact: "Contact",
  },
  "ko-KR": {
    quickLinks: "빠른 링크",
    aboutUs: "회사 소개",
    ourOffices: "우리의 사무실",
    contact: "연락처",
  },
};

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(() => {
    // Initialize from localStorage or default to 'en'
    return localStorage.getItem("language") || "en";
  });

  useEffect(() => {
     console.log("Current language:", language);
    // Save the selected language to localStorage whenever it changes
    localStorage.setItem("language", language);
  }, [language]);

  // Ensure the translations object exists for the current language
  const t = translations[language] || translations.en; // Default to 'en' if language not found

  return (
    <LanguageContext.Provider value={{ language, setLanguage, t }}>
      {children}
    </LanguageContext.Provider>
  );
};

// Hook to use the language context
export const useLanguage = () => useContext(LanguageContext);
