import React, { useState, useEffect } from "react";
import "./TechnologyExpertise.css";
import arrowRight from "../../assets/utility_images/arrow-right.svg";
import { useQuery } from "@tanstack/react-query";
import { useLanguage } from "../../Context/LanguageContext";
import { Row, Col, Container } from "react-bootstrap";
import techexpertise from "../../assets/utility_images/techexpertise.svg";
import corner1 from "../../assets/utility_images/corner1.svg";
import corner2 from "../../assets/utility_images/corner2.svg";
import ContactModal from "../../components/ContactModal/ContactModal";
import Loader from "../../components/Loader/Loader";
import fallbackDataTechExpertiseEN from "../../FallbackAPI/TechnologyExpertiseLocal/FallbackApiTechnologyExpertiseEN.json";
import fallbackDataTechExpertiseKR from "../../FallbackAPI/TechnologyExpertiseLocal/FallbackApiTechnologyExpertiseKR.json";

const TechnologyExpertise = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [selectedCategory, setSelectedCategory] = useState("all");
  const [logosPerRow, setLogosPerRow] = useState(6); // Default value

  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { language } = useLanguage();

  const {
    data: TechExpertiseApiData,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["TechExpertiseData", language],
    queryFn: async () => {
      try {
        const response = await fetch(
          `${baseUrl}/page/TechnologyExpertise/${language}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch Technology Expertise data");
        }
        return response.json();
      } catch (error) {
        console.warn("API request failed, using fallback data.");
        return language === "en" ? fallbackDataTechExpertiseEN : fallbackDataTechExpertiseKR; // Return fallback data when API fails
      }
    },
    staleTime: 10 * 60 * 1000, // Cache data for 10 minutes
    cacheTime: 60 * 60 * 1000, // Keep data in cache for 1 hour
    retry: 2, // Retry twice on failure
    refetchOnWindowFocus: false,
    onError: (error) => {
      console.error("Error fetching data:", error);
    },
  });

  // Adjust the number of logos per row based on screen size
  const calculateLogosPerRow = () => {
    const width = window.innerWidth;
    if (width >= 1200) return 6;
    if (width >= 768) return 4;
    if (width >= 578) return 3;
    return 2;
  };

  useEffect(() => {
    const updateLogosPerRow = () => setLogosPerRow(calculateLogosPerRow());
    updateLogosPerRow();
    window.addEventListener("resize", updateLogosPerRow);
    return () => window.removeEventListener("resize", updateLogosPerRow);
  }, []);

  // Map and structure the data for all the technologies
  const logos =
    TechExpertiseApiData?.sections?.[0]?.sectionItems.map((item) => ({
      id: item.id,
      category: item.title.toLowerCase(), // Use `title` as category
      imageUrl: item.imageUrl,
      order: item.order,
    })) || [];

  const sortedLogos = [...logos].sort((a, b) => a.order - b.order); //sort on the basis of order number

  // Filter logos based on the selected category
  const filteredLogos = sortedLogos.filter(
    (logo) => selectedCategory === "" || logo.category === selectedCategory
  );

  // to calculate the number of lines need to be there above the logos
  const numberOfRows = Math.ceil(filteredLogos.length / logosPerRow);

  const handleTabClick = (category) => {
    setSelectedCategory(category);
  };

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error loading data.</div>;
  }

  const renderSection = (section) => {
    switch (section.sectionName) {
      case "TechnologySection":
        return (
          <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <section className="technology-section">
              <div className="technology">
                <div className="corner-1">
                  <img src={corner1} alt="" />
                </div>
                <div className="heading-container d-flex flex-column align-items-start">
                  <div className="section-heading d-flex align-items-center">
                    <div className="line"></div>
                    <div className="section-title">{section.heading}</div>
                  </div>
                  <div className="technology-header">{section.introText}</div>
                </div>

                {/* Tab buttons */}
                <div className="tabs">
                  <button
                    className={`tab-link ${
                      selectedCategory === "all" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("all")}
                  >
                    All
                    {/* translate based on language */}
                  </button>
                  <button
                    className={`tab-link ${
                      selectedCategory === "testing" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("testing")}
                  >
                    QA AND TESTING
                    {/* translate based on language */}
                  </button>
                  <button
                    className={`tab-link ${
                      selectedCategory === "development" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("development")}
                  >
                    DEVELOPMENT
                    {/* translate based on language */}
                  </button>
                </div>

                {/* Logos container */}
                <div className="logo-container">
                  {isLoading && <p>Loading...</p>}
                  {error && <p>Error loading data.</p>}
                  {!isLoading &&
                    !error &&
                    filteredLogos.length > 0 &&
                    Array.from({ length: numberOfRows }).map((_, rowIndex) => (
                      <Row
                        key={rowIndex}
                        className="horizontal-line d-flex flex-wrap justify-content-center"
                      >
                        {filteredLogos
                          .slice(
                            rowIndex * logosPerRow,
                            rowIndex * logosPerRow + logosPerRow
                          )
                          .map((logo) => (
                            <Col
                              className="logos"
                              key={logo.id}
                              xs={6}
                              sm={4}
                              md={3}
                              lg={3}
                              xl={2}
                            >
                              <img src={logo.imageUrl} alt={logo.alt} />
                            </Col>
                          ))}
                      </Row>
                    ))}
                </div>
                <div className="corner-2">
                  <img src={corner2} alt="" />
                </div>
              </div>
            </section>
          </Container>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {TechExpertiseApiData.viewPort.isEnabled && (
        <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
          <section className="Hero">
            <div className="hero-section">
              <div className="hero-banner d-flex">
                <div className="background-hero d-flex justify-content-start align-items-center">
                  <img src={techexpertise} alt="" />
                  <div className="hero-description d-flex flex-column">
                    <div className="hero-heading">
                      {TechExpertiseApiData.viewPort.heading
                        .split(",")
                        .map((part, index) => (
                          <React.Fragment key={index}>
                            <span>{part.trim()}</span>
                            {/* Insert a <br /> after each part except the last one */}
                            {index !==
                              TechExpertiseApiData.viewPort.heading.split(",")
                                .length -
                                1 && <br />}
                          </React.Fragment>
                        ))}
                    </div>
                    <div className="hero-paragraph">
                      <p>{TechExpertiseApiData.viewPort.description}</p>
                    </div>
                    <button
                      className="btn-hero d-flex justify-content-evenly align-items-center"
                      onClick={handleShow} // Show modal on click
                    >
                      {TechExpertiseApiData.viewPort.buttonText}
                      <div className="hero-btn">
                        <img src={arrowRight} alt="Arrow" />
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Container>
      )}
      <ContactModal show={show} handleClose={handleClose} />

      {TechExpertiseApiData.sections
        .filter((section) => section.isEnabled) // Filter sections
        .sort((a, b) => a.sectionOrder - b.sectionOrder) // Sort sections by sectionOrder
        .map((section) => renderSection(section))}
    </div>
  );
};

export default TechnologyExpertise;
