import React from "react";
import "./DeliveryCard.css";

const DeliveryCard = ({ description, heading }) => {
  const descriptionItems = description
    .split(".")
    .filter((sentence) => sentence.trim() !== "");
  return (
    <div className="delivery-card d-flex flex-column align-items-center">
      <div className="delivery-heading">{heading}</div>
      <div className="delivery-line"></div>
      <div className="delivery-description">
        {descriptionItems.map((sentence, index) => (
          <li key={index}>{sentence.trim()}.</li> // Add the period back
        ))}
      </div>
    </div>
  );
};

export default DeliveryCard;
