import React from 'react'
import "./JobCard.css"

const JobCard = ({heading}) => {
  return (
    <div className="job-card">
      <div className="card-border"></div>
      <div className="job-name">{heading}</div>
    </div>
  );
}

export default JobCard
