import React, { useRef, useEffect, useState } from "react";
import { gsap } from "gsap";
import "./Loader.css";

const Loader = () => {
  const ballRef = useRef(null);
  const shadowRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true); // State to control loader visibility

  useEffect(() => {
    const bounceVars = {
      y: -150,
      ease: "sine.out",
    };

    const shadowVars = {
      transformOrigin: "center",
      scale: 0.75,
      autoAlpha: 0.25,
      ease: "sine.out",
    };

    const flattenVars = {
      transformOrigin: "bottom",
      scaleY: 0.7,
      repeat: 1,
      ease: "circ.out",
    };

    const bounce = gsap.timeline({ repeat: 1, yoyo: true });
    const master = gsap.timeline({ repeat: -1 });

    bounce
      .to(ballRef.current, { duration: 1, ...bounceVars })
      .to(shadowRef.current, { duration: 1, ...shadowVars }, 0);

    master
      .add(bounce)
      .to(ballRef.current, { duration: 0.1, ...flattenVars })
      .timeScale(2.2);

    // Fade out the loader after 200ms

    return () => {
      master.kill(); // Cleanup animations
    };
  }, []);

  return (
    <div
      id="loader"
      // style={{ opacity: isLoading ? 1 : 0, transition: "opacity 0.5s ease" }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 152">
        <defs>
          <filter id="blur" x="0" y="0">
            <feGaussianBlur in="SourceGraphic" stdDeviation="0.5" />
          </filter>
        </defs>
        <ellipse
          id="shadow"
          ref={shadowRef}
          fill="#006df0"
          cx="25"
          cy="149" // Position the shadow correctly
          rx="16"
          ry="2"
          filter="url(#blur)"
        />
        <circle
          id="ball"
          ref={ballRef}
          fill="#006df0"
          cx="25"
          cy="130" // Ball's starting position
          r="19"
        />
      </svg>
    </div>
  );
};

export default Loader;
