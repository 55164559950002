import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import NavbarComp from "./components/nav-bar/NavbarComp";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { LanguageProvider } from "./Context/LanguageContext";

import HomePage from "./Pages/Home/HomePage";
import AboutUs from "./Pages/AboutUs/AboutUs";
import WhyUs from "./Pages/WhyUs/WhyUs";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import FooterComp from "./components/Footer/FooterComp";
import OurOfferings from "./Pages/OurOfferings/OurOfferings";
import TechnologyExpertise from "./Pages/TechnologyExpertise/TechnologyExpertise";
import Careers from "./Pages/Careers/Careers";
import Loader from "./components/Loader/Loader";
import { useEffect, useState } from "react";
import Discover from "./Pages/DiscoverMorePage/DiscoverMorePage";
import PrivacyPage from "./Pages/PrivacyPage/PrivacyPage";
import JobApplication from "./Pages/JobApplication/JobApplication";
import JobCard from "./components/JobCard/JobCard";

function App() {
  return (
    <LanguageProvider>
      <BrowserRouter>
        <LoaderWrapper />
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </LanguageProvider>
  );
}
const LoaderWrapper = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Start loading when location changes
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500); // Adjust the timeout duration as needed (e.g., 500ms)

    return () => clearTimeout(timer); // Clean up the timer
  }, [location]);

  return (
    <>
      {loading ? (
        <div className="loader-overlay">
          <Loader /> {/* Show loader when loading */}
        </div>
      ) : (
        <>
          <NavbarComp />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/AboutUs" element={<AboutUs />} />
            <Route path="/whyus" element={<WhyUs />} />
            <Route path="/ourofferings" element={<OurOfferings />} />
            <Route
              path="/technologyexpertise"
              element={<TechnologyExpertise />}
            />
            <Route path="/careers" element={<Careers />} />
            <Route path="/loader" element={<Loader />} />
            <Route path="/discover" element={<Discover />} />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/jobs" element={<JobApplication />} />
            <Route path="/jobs/:jobName" element={<JobApplication />} />

            <Route path="/discover/:slug" element={<Discover />} />
          </Routes>
          <FooterComp />
        </>
      )}
    </>
  );
};

export default App;
