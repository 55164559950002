import React from "react";
import "./HomePage.css";
import InfoCard from "../../components/InfoCard/InfoCard";
import OfferingCard from "../../components/OfferingCard/OfferingCard";
import ProcessCard from "../../components/ProcessCard/ProcessCard";
import arrowRight from "../../assets/utility_images/arrow-right.svg";
import Loader from "../../components/Loader/Loader";
import TestimonialCarousel from "../../components/TestimonialCarousel/TestimonialCarousel";
import { Row, Col, Container } from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";
import { useLanguage } from "../../Context/LanguageContext";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import fallbackDataHomePageEN from "../../FallbackAPI/HomePageLocal/FallbackApiHomePageEN.json";
import fallbackDataHomePageKR from "../../FallbackAPI/HomePageLocal/FallbackApiHomePageKR.json";

// Helper function to get the color for the process cards based on order
const getColorByOrder = (order) => {
  const colors = ["#FFAE02", "#FF334E", "#152E78"]; // Add more colors as needed
  return colors[order % colors.length]; // Rotate colors if more cards exist than colors
};

const HomePage = () => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { language } = useLanguage();

  const {
    data: HomePageApiData,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["homePageData", language],
    queryFn: async () => {
      try {
        const response = await fetch(`${baseUrl}/page/HomePage/${language}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch home page data");
        }
        return response.json();
      } catch (error) {
        console.warn("API request failed, using fallback data.");
       return language === "en"
         ? fallbackDataHomePageEN
         : fallbackDataHomePageKR; // Return fallback data when API fails
      }
    },
    staleTime: 10 * 60 * 1000, // Cache data for 10 minutes before considering it stale
    cacheTime: 60 * 60 * 1000, // Keep data in cache for 1 hr
    retry: 2, // Retry twice on failure
    refetchOnWindowFocus: false, // Prevent refetch on window focus
    onError: (error) => {
      console.error("Error fetching data:", error);
    },
  });

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error loading data.</div>;
  }

  // Helper function to render sections based on sectionName
  const renderSection = (section) => {
    switch (section.sectionName) {
      case "IntroSection":
        return (
          <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <section className="Intro-section">
              <div
                className="intro-wrapper d-flex flex-column align-items-center justify-content-between"
                id="intro-home"
              >
                <div className="intro-content d-flex flex-column align-items-center">
                  <div className="title">{section.heading}</div>
                  <div className="intro-desc">{section.introText}</div>
                  <Row xs={1} sm={2} md={2} lg={3}>
                    {section.sectionItems
                      .filter((item) => item.isEnabled) // Filter items based on isEnabled
                      .sort((a, b) => a.order - b.order) // Sort items by order
                      .map((item) => (
                        <Col
                          className="px-xl-4 pb-md-4 pb-sm-4 pb-4"
                        >
                          <InfoCard
                            title={item.title}
                            description={item.description}
                            imageUrl={item.imageUrl}
                          />
                        </Col>
                      ))}
                  </Row>
                </div>
              </div>
            </section>
          </Container>
        );
      case "Offerings":
        return (
          <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <section className="Offerings">
              <div className="offering-section d-flex flex-column">
                <div className="section-heading d-flex align-items-center">
                  <div className="line"></div>
                  <div className="section-title">{section.heading}</div>
                </div>
                <div className="offering-header d-flex justify-content-between align-items-center">
                  <div className="heading">{section.introText}</div>
                  <Link className="linkToPage" to="/ourofferings">
                    {" "}
                    <button className="btn-offering d-flex justify-content-evenly align-items-center">
                      {section.buttonText}
                      <img src={arrowRight} alt="" />
                    </button>
                  </Link>
                </div>
                <div className="offering-cards-box">
                  <Row xs={12} sm={2} md={2} lg={3} xl={4}>
                    {section.sectionItems
                      .filter((item) => item.isEnabled)
                      .sort((a, b) => a.order - b.order)
                      .map((item, index) => (
                        <Col className="pb-lg-4 pb-md-4 pb-4">
                          <OfferingCard
                            number={index + 1}
                            icon={item.imageUrl}
                            title={item.title}
                            description={item.description}
                            buttonName={item.buttonText}
                          />
                        </Col>
                      ))}
                  </Row>
                </div>
              </div>
            </section>
          </Container>
        );
      case "OurProcess":
        return (
          <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <section className="Our-Process">
              <div className="our-process-container d-flex flex-column">
                <div className="section-heading d-flex align-items-center">
                  <div className="line"></div>
                  <div className="section-title">{section.heading}</div>
                </div>
                <div className="process-header d-flex align-items-center">
                  {section.introText}
                </div>
                <div className="process-card-container">
                  <Row xs={12} sm={1} md={2} lg={2} xl={3}>
                    {section.sectionItems
                      .filter((item) => item.isEnabled) // Filter enabled items
                      .sort((a, b) => a.order - b.order) // Sort by order
                      .map((item, index) => (
                        <Col className="pb-lg-4 pb-md-4 pb-4">
                          <ProcessCard
                            icon={item.imageUrl} // Pass icon from API or default
                            heading={item.title} // Pass heading from API
                            description={item.description} // Pass description from API
                            cardNumber={index + 1} // Pass card number based on the order
                            processImageColor={getColorByOrder(index)}
                          />
                        </Col>
                      ))}
                  </Row>
                </div>
              </div>
            </section>
          </Container>
        );
      case "Testimonial":
        return (
          <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <section className="Testimony">
              <div className="box"></div>
              <div className="testimony-section d-flex flex-column align-items-center">
                <div className="heading-container d-flex flex-column align-items-start">
                  <div className="section-heading d-flex align-items-center">
                    <div className="line"></div>
                    <div className="section-title">{section.heading}</div>
                  </div>
                  <div className="testimonial-header">{section.introText}</div>
                </div>
                <TestimonialCarousel testimonials={section.sectionItems} />
              </div>
            </section>
          </Container>
        );
      case "Connect":
        return (
          <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <section className="Connect">
              <div className="connect-section">
                <div className="connect-box d-flex align-items-center justify-content-between">
                  <div className="connect-text d-flex flex-column">
                    <div className="text1">
                      <span>{section.heading}</span>
                    </div>
                    <div className="text2">{section.introText}</div>
                  </div>
                  <div className="btn-div d-flex justify-content-end">
                    <Link className="linkToPage" to="/ourofferings">
                      <button className="btn-connect d-flex justify-content-evenly align-items-center">
                        {section.buttonText}
                        <img src={arrowRight} alt="" />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </section>
          </Container>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {HomePageApiData.viewPort.isEnabled && ( // Check if viewPort is enabled
        <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
          <section className="Hero">
            <div className="hero-section">
              <div className="hero-banner">
                <div className="background-hero d-flex justify-content-start align-items-center">
                  <img src={HomePageApiData.viewPort.illustrationUrl} alt="" />
                  <div className="hero-description d-flex flex-column">
                    <div className="hero-heading">
                      <span>
                        {HomePageApiData.viewPort.heading
                          .split(" ")
                          .slice(0, 2)
                          .join(" ")}
                      </span>
                      <br />
                      <span>
                        {HomePageApiData.viewPort.heading.split(" ").slice(2).join(" ")}
                      </span>
                    </div>
                    <div className="hero-paragraph">
                      <p>{HomePageApiData.viewPort.description}</p>
                    </div>
                    <ScrollLink
                      to="intro-home"
                      smooth={true}
                      duration={500}
                      offset={-50}
                      className="linkToPage"
                    >
                      {" "}
                      <button className="btn-hero d-flex justify-content-evenly align-items-center">
                        {HomePageApiData.viewPort.buttonText}
                        <div className="hero-btn">
                          <img src={arrowRight} alt="" />
                        </div>
                      </button>
                    </ScrollLink>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Container>
      )}

      {/* Filter and sort sections based on isEnabled and sectionOrder */}
      {HomePageApiData.sections
        .filter((section) => section.isEnabled) // Filter sections
        .sort((a, b) => a.sectionOrder - b.sectionOrder) // Sort sections by sectionOrder
        .map((section) => renderSection(section))}
    </div>
  );
};

export default HomePage;
