import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { v4 as uuidv4 } from "uuid";
import { useLanguage } from "../../Context/LanguageContext";
import arrowRight from "../../assets/utility_images/arrow-right.svg";
import { Row, Col, Container } from "react-bootstrap";
import PillarCard from "../../components/PillarCard/PillarCard";
import "./WhyUs.css";
import DeliveryCard from "../../components/DeliveryCard/DeliveryCard";
import corner1 from "../../assets/utility_images/corner1.svg";
import corner2 from "../../assets/utility_images/corner2.svg";
import { Link as ScrollLink } from "react-scroll";
import Loader from "../../components/Loader/Loader";
import fallbackDataWhyUsEN from "../../FallbackAPI/WhyUsLocal/FallbackApiWhyUsEN.json";
import fallbackDataWhyUsKR from "../../FallbackAPI/WhyUsLocal/FallbackApiWhyUsKR.json";

const WhyUs = () => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { language } = useLanguage();

  const {
    data: WhyUsApiData,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["WhyUsData", language],
    queryFn: async () => {
      try {
        const response = await fetch(`${baseUrl}/page/WhyUs/${language}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch home page data");
        }
        return response.json();
      } catch (error) {
        console.warn("API request failed, using fallback data.");
        return language === "en"
          ? fallbackDataWhyUsEN
          : fallbackDataWhyUsKR; // Return fallback data when API fails
      }
    },
    staleTime: 10 * 60 * 1000, // Cache data for 10 minutes before considering it stale
    cacheTime: 60 * 60 * 1000, // Keep data in cache for 1 hr
    retry: 2, // Retry twice on failure
    refetchOnWindowFocus: false, // Prevent refetch on window focus
    onError: (error) => {
      console.error("Error fetching data:", error);
    },
  });

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error loading data.</div>;
  }

  const renderSection = (section) => {
    switch (section.sectionName) {
      case "WhyUsIntro":
        return (
          <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <section className="page-intro-section" id="intro-whyus">
              <div className="page-intro ">
                <div className="corner-1">
                  <img src={corner1} alt="" />
                </div>
                <div className="page-intro-content d-flex">
                  <div className="page-intro-img">
                    <img src={section.imageUrl} alt="" />
                  </div>

                  <div className="page-intro-description">
                    {" "}
                    <div className="heading-container d-flex flex-column align-items-start">
                      <div className="section-heading d-flex align-items-center">
                        <div className="line"></div>
                        <div className="section-title">{section.heading}</div>
                      </div>
                      <div className="value-header">{section.introText}</div>
                      <div className="intro-description">
                        {section.description
                          .split("<!--split-->") // Split at the specified delimiter
                          .map((paragraph, index) => (
                            <p key={index}>{paragraph}</p>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="corner-2">
                  <img src={corner2} alt="" />
                </div>
              </div>
            </section>
          </Container>
        );

      case "PillarSection":
        return (
          <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <section className="pillar-section">
              <div className="pillar">
                <div className="heading-container d-flex flex-column align-items-start">
                  <div className="section-heading d-flex align-items-center">
                    <div className="line"></div>
                    <div className="section-title">{section.heading}</div>
                  </div>
                  <div className="pillar-header">{section.introText}</div>
                </div>
                <div className="pillar-container">
                  {" "}
                  <Row xs={1} sm={2} md={2} lg={3} xl={3}>
                    {section.sectionItems
                      .filter((item) => item.isEnabled) // Filter enabled items
                      .sort((a, b) => a.order - b.order) // Sort by order
                      .map((item, index) => (
                        <Col className="pb-5">
                          <PillarCard
                            background={item.imageUrl} // Pass icon from API or default
                            heading={item.title} // Pass heading from API
                          />
                        </Col>
                      ))}
                    {/* <Col className="pb-5"><PillarCard/></Col> */}
                  </Row>
                </div>
              </div>
            </section>
          </Container>
        );

      case "TargetclientSection":
        return (
          <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <section className="target-section">
              <div className="target">
                <div className="heading-container d-flex flex-column align-items-start">
                  <div className="section-heading d-flex align-items-center">
                    <div className="line"></div>
                    <div className="section-title">{section.heading}</div>
                  </div>
                  <div className="target-header">{section.introText}</div>
                </div>
                <div className="target-container d-flex flex-wrap justify-content-around">
                  {section.sectionItems
                    .filter((item) => item.isEnabled) // Filter enabled items
                    .sort((a, b) => a.order - b.order) // Sort by order
                    .map((item, index) => (
                      <div className="target-card pb-5 d-flex justify-content-center align-items-center">
                        <img src={item.imageUrl} alt="" />
                        <div className="target-domain ">{item.title}</div>
                      </div>
                    ))}
                </div>
              </div>
            </section>
          </Container>
        );

      case "DeliverySection":
        return (
          <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <section className="delivery-execution">
              <div className="delivery">
                <div className="heading-container d-flex flex-column align-items-start">
                  <div className="section-heading d-flex align-items-center">
                    <div className="line"></div>
                    <div className="section-title">{section.heading}</div>
                  </div>
                  <div className="target-header">{section.introText}</div>
                </div>
                <div className="delivery-card-container">
                  <Row xs={1} sm={1} md={2} lg={2} xl={3}>
                    {section.sectionItems
                      .filter((item) => item.isEnabled) // Filter enabled items
                      .sort((a, b) => a.order - b.order) // Sort by order
                      .map((item, index) => (
                        <Col className="pb-5">
                          <DeliveryCard
                            description={item.description} // Pass icon from API or default
                            heading={item.title} // Pass heading from API
                          />
                        </Col>
                      ))}
                  </Row>
                </div>
              </div>
            </section>
          </Container>
        );

      case "PartnerSection":
        return (
          <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <section className="client-section">
              <div className="clients">
                {" "}
                <div className="heading-container d-flex flex-column align-items-start">
                  <div className="section-heading d-flex align-items-center">
                    <div className="line"></div>
                    <div className="section-title">{section.heading}</div>
                  </div>
                  <div className="target-header">{section.introText}</div>
                </div>
               <Row xs={1} sm={2} md={2} lg={3} xl={4}>
                  {section.sectionItems
                    .filter((item) => item.isEnabled) // Filter enabled items
                    .sort((a, b) => a.order - b.order) // Sort by order
                    .map((item, index) => (
                      <div className="targetclient-image px-3 pb-5">
                        <img src={item.imageUrl} alt="" />
                      </div>
                    ))}
                </Row>
              </div>
            </section>
          </Container>
        );

      default:
        return null;
    }
  };
  
  return (
    <div>
      {WhyUsApiData.viewPort.isEnabled && (
        <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
          <section className="Hero">
            <div className="hero-section">
              <div className="hero-banner d-flex">
                <div className="background-hero d-flex justify-content-start align-items-center">
                  <img src={WhyUsApiData.viewPort.illustrationUrl} alt="" />
                  <div className="hero-description d-flex flex-column">
                    <div className="hero-heading">
                      {WhyUsApiData.viewPort.heading
                        .split(",")
                        .map((part, index) => (
                          <React.Fragment key={index}>
                            <span>{part.trim()}</span>
                            {/* Insert a <br /> after each part except the last one */}
                            {index !==
                              WhyUsApiData.viewPort.heading.split(",").length -
                                1 && <br />}
                          </React.Fragment>
                        ))}
                    </div>
                    <div className="hero-paragraph">
                      <p>{WhyUsApiData.viewPort.description}</p>
                    </div>
                    <ScrollLink
                      to="intro-whyus"
                      smooth={true}
                      duration={500}
                      offset={-50}
                      className="linkToPage"
                    >
                      {" "}
                      <button className="btn-hero d-flex justify-content-evenly align-items-center">
                        {WhyUsApiData.viewPort.buttonText}
                        <div className="hero-btn">
                          <img src={arrowRight} alt="" />
                        </div>
                      </button>
                    </ScrollLink>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Container>
      )}
      {WhyUsApiData.sections
        .filter((section) => section.isEnabled) // Filter sections
        .sort((a, b) => a.sectionOrder - b.sectionOrder) // Sort sections by sectionOrder
        .map((section) => renderSection(section))}
    </div>
  );
};

export default WhyUs;
