import React from "react";
import grey from "../../assets/utility_images/valuecard-background-grey.svg";
import blue from "../../assets/utility_images/valuecard-background-blue.svg";
import "./ValueCard.css";
import valueicon from "../../assets/utility_images/value-icon.svg"

const ValueCard = ({icon,heading,description}) => {
  return (
    <div className="value-card d-flex justify-content-center align-items-center">
        <div className="value-icon-container">
            <div className="value-icon-background">
                <img src={icon} alt="" />
            </div>
        </div>
      <div className="grey-background">
        <img src={grey} alt="grey background" />
      </div>
      <div className="value-text d-flex flex-column">
        <div className="value-title">{heading}</div>
        <div className="value-description">
         {description}
        </div>
      </div>
      <div className="blue-background">
        <img src={blue} alt="blue background" />
      </div>
    </div>
  );
};

export default ValueCard;
