import React, { useState } from "react";
import "./JobApplication.css";
import { Container } from "react-bootstrap";
import JobApplicationModal from "../../components/JobApplicationModal/JobApplicationModal";
import { useParams } from "react-router-dom";
import { useLanguage } from "../../Context/LanguageContext";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../components/Loader/Loader";

const JobApplication = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { jobName } = useParams();
  const { language } = useLanguage();
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const {
    data: JobApplicationApiData,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["JobApplication", jobName, language],
    queryFn: async () => {
      const response = await fetch(`${baseUrl}/page/${jobName}/${language}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch job details");
      }
      return response.json();
    },
    staleTime: 10 * 60 * 1000,
    cacheTime: 60 * 60 * 1000,
  });

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error loading data.</div>;
  }

  // Extract and process sections
  const sections = JobApplicationApiData.sections
    ?.filter((section) => section.isEnabled) // Filter out disabled sections
    ?.sort((a, b) => a.order - b.order); // Sort sections by order

  const firstSection = sections?.[0]; // Get the first section
  const otherSections = sections?.slice(1); // Get the remaining sections

  return (
    <div>
      <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
        <section className="privacy-policy-section">
          <div className="privacy-policy">
            {/* Render the first section separately */}
            {firstSection && (
              <div key={firstSection.id} className="policy-description">
                <div className="privacy-title">{firstSection.heading}</div>
                <div className="privacy-description">
                  {firstSection.description.split("\r\n").map((text, index) => (
                    <p key={index}>{text}</p>
                  ))}
                </div>
                {firstSection.buttonText && (
                  <button
                    className="btn-job"
                    onClick={handleShow} // Show modal on click
                  >
                    {firstSection.buttonText}
                  </button>
                )}
              </div>
            )}

            {/* Render the other sections */}
            {otherSections.map((section) => (
              <div key={section.id} className="policy-description">
                <div className="value-header mt-4">{section.heading}</div>
                <div className="privacy-description">
                  <ul>
                    {section.description
                      .split(".") // Split the description into sentences
                      .filter((sentence) => sentence.trim()) // Remove any empty strings
                      .map((sentence, index) => (
                        <li key={index}>{sentence.trim()}.</li> // Add the period back after trimming
                      ))}
                  </ul>
                </div>
                {section.buttonText && (
                  <button
                    className="btn-job"
                    onClick={handleShow} // Show modal on click
                  >
                    {section.buttonText}
                  </button>
                )}
              </div>
            ))}
          </div>
        </section>
      </Container>
      <JobApplicationModal show={show} handleClose={handleClose} />
    </div>
  );
};

export default JobApplication;
