import React from 'react'
import "./PillarCard.css"

const PillarCard = ({background,heading}) => {
  return (
    <div>
      <div className="pillar-card d-flex flex-column align-items-center justify-content-start">
        <div className="pillar-image">
          <img src={background} alt="" />
        </div>
        <div className="pillar-card-content d-flex flex-column align-items-center">
          <div className="pillar-heading">{heading}</div>
        </div>
      </div>
    </div>
  );
}

export default PillarCard
